import axios from 'axios';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { useState, createContext, useContext, useEffect } from 'react';
import { useAuth } from 'components/AuthProvider';

const COOKIE_NAME = 'zvbstoredqueue';
const UNIQUE_ID_NAME = 'zvbstoreduniqueid';
const RAND_SEND_MIN_MS = 2000;
const RAND_SEND_MAX_MS = 4000;

const basePath = `/${process.env.REACT_APP_API_NAME}/methods/`;
const baseUrl = `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT.length > 0 ? `:${process.env.REACT_APP_API_PORT}` : ``)}${basePath}`;

export const LocalStoreContext = createContext(null);

export const useLocalStore = () => {
	return useContext(LocalStoreContext);
};

export default function LocalStoreProvider(props) {
	const { accessConfig } = useAuth();

	const storeQueue = () => {
		localStorage.setItem(COOKIE_NAME, JSON.stringify(queue));
	};

	const getStoredQueue = () => {
		const queueStr = localStorage.getItem(COOKIE_NAME);
		return queueStr ? JSON.parse(queueStr) : [];
	};

	const [deviceUniqueId, setDeviceUniqueId] = useState(null);
	const [queue, setQueue] = useState(getStoredQueue());

	const makeid = (length) => {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return new Date().getTime() + '#' + getBrowserFingerprint() + '#' + result;
	}

	useEffect(() => {
		const uid = localStorage.getItem(UNIQUE_ID_NAME) || makeid(64);
		localStorage.setItem(UNIQUE_ID_NAME, uid);
		setDeviceUniqueId(uid);
		axios.post(baseUrl + 'testExamControl/storeHash', { hashCode: uid }).then(function (response) {
			console.log('Send successful.');
		}).catch(function (error) {
			console.log('Send error.');
			console.log(error);
		});
	}, []);

	useEffect(() => {
		storeQueue(queue);
	}, [queue]);


	const addAnswerToQueue = (answer) => {
		const vizsgazoId = accessConfig.vizsgazoId;

		console.log('Add answers to queue:');
		console.log(answer);

		const _queue = [...queue];

		const randomTimer = Math.floor(Math.random() * (RAND_SEND_MAX_MS - RAND_SEND_MIN_MS + 1) + RAND_SEND_MIN_MS);
		const storeObj = { 'item': answer, 'timestamp': Date.now(), 'timer': randomTimer, 'sendableTime': Date.now() + randomTimer, 'belongsTo': vizsgazoId };

		let indexInQueue = -1;
		for (let i = 0; i < queue.length; i++)
			if (queue[i].item.vizsgafeladatId === answer.vizsgafeladatId)
				indexInQueue = i;

		if (indexInQueue > -1)
			_queue[indexInQueue] = storeObj;
		else
			_queue.push(storeObj);

		setQueue(_queue);
	};

	const removeAnswersFromQueue = (answers) => {
		console.log('Remove answers from queue:');
		console.log(answers);

		let _queue = [...queue];

		let removeIndexArray = [];
		for (let i = 0; i < _queue.length; i++) {
			for (let j = 0; j < answers.length; j++) {
				if (_queue[i].item.vizsgafeladatId === answers[j].vizsgafeladatId)
					removeIndexArray.push(i);
			}
		}
		removeIndexArray.sort(function (a, b) { return a - b; });
		for (var i = removeIndexArray.length - 1; i >= 0; i--)
			_queue.splice(removeIndexArray[i], 1);

		setQueue(_queue);
	};

	const sendWithStorage = (answer, force, cbSuccess, cbFail) => {
		const vizsgafeladatsorId = accessConfig.vizsgafeladatsorId;
		const vizsgaId = accessConfig.vizsgaId;
		const tesztlapId = accessConfig.tesztlapId;
		const vizsgazoId = accessConfig.vizsgazoId;

		let sendableAnswers = [];
		for (let i = 0; i < queue.length; i++)
			if (queue[i].belongsTo == vizsgazoId)
				if (force || queue[i].sendableTime <= Date.now())
					sendableAnswers.push(queue[i].item);

		let indexInSendable = -1;

		if (answer) {
			for (let i = 0; i < sendableAnswers.length; i++)
				if (sendableAnswers[i].vizsgafeladatId === answer.vizsgafeladatId)
					indexInSendable = i;

			if (indexInSendable > -1)
				sendableAnswers[indexInSendable] = answer;
			else
				sendableAnswers.push(answer);
		}

		let body = { vizsgafeladatsorId: vizsgafeladatsorId, vizsgaId: vizsgaId, tesztlapId: tesztlapId, valaszok: sendableAnswers };
		axios.post(baseUrl + 'vizsgazoValaszControl/storeExamineeAnswer', { input: body }).then(function (response) {
			if (response.data && response.data.isSuccessful) {
				console.log('Send successful.');
				removeAnswersFromQueue(sendableAnswers);
				if (cbSuccess) cbSuccess();
			} else {
				console.log('Send unsuccessful.');
				if (answer) addAnswerToQueue(answer);
				saveAnswer(body);
				if (cbFail) cbFail();
			}
		}).catch(function (error) {
			console.log('Send error.');
			console.log(error);
			if (answer) addAnswerToQueue(answer);
			saveAnswer(body);
			if (cbFail) cbFail();
		});
	};

	const lockWithStorage = (cbSuccess, cbFail) => {
		axios.post(baseUrl + 'vizsgazoValaszControl/lockExamineeAnswer', {}).then(function (response) {
			if (response.data) {
				console.log('Lock successful.');
				// removeAnswersFromQueue(sendableAnswers);
				if (cbSuccess) cbSuccess(response.data);
			} else {
				console.log('Lock unsuccessful.');
				// if (answer) addAnswerToQueue(answer);
				if (cbFail) cbFail();
			}
		}).catch(function (error) {
			console.log('Lock error.');
			console.log(error);
			// if (answer) addAnswerToQueue(answer);
			if (cbFail) cbFail();
		});
	};

	const saveAnswer = (body, cbSuccess, cbFail) => {
		const examineeId = accessConfig.vizsgazoId;

		axios.post(baseUrl + 'vizsgazoValaszControl/saveAnswer', { examineeId: examineeId, input: body }).then(function (response) {
			if (response.data) {
				if (cbSuccess) cbSuccess(response.data);
			} else {
				console.log('Save unsuccessful.');
				// if (answer) addAnswerToQueue(answer);
				if (cbFail) cbFail();
			}
		}).catch(function (error) {
			console.log('Save error.');
			console.log(error);
			// if (answer) addAnswerToQueue(answer);
			if (cbFail) cbFail();
		});
	};


	const value = {
		sendWithStorage,
		lockWithStorage,
		queue,
	};

	return <LocalStoreContext.Provider value={value}>{props.children}</LocalStoreContext.Provider>;
}
