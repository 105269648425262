import { motion } from 'framer-motion';
import GlassCard from 'elements/GlassCard';

const compareFn = (a, b) => {
	const _a = Number.parseInt(a.szam, 10);
	const _b = Number.parseInt(b.szam, 10);
	if (_a < _b) return -1;
	if (_a > _b) return 1;
	return 0;
};

export default function ExamItem(props) {
	return (
		<GlassCard
			className={'max-w-3xl ' + (props.className || '')}
			topChildren={<ExamQuestion number={props.number} data={props.data} />}
			mdlChildren={
				<>
					{props.data &&
						props.data.feladatTipusId === 3 &&
						props.data.asszociaciosLeirasTetel.length > 0 &&
						props.data.asszociaciosLeirasTetel.map((item, index) => (
							<ExamAnswerItem key={`${props.data.vizsgafeladatId}-${item.id}`} index={index} data={item} checked={props.answer && props.answer.valaszId === item.id} onChange={() => props.onAnswerChange(item.id)} />
						))}
					{props.data &&
						(props.data.feladatTipusId === 1 || props.data.feladatTipusId === 2 || props.data.feladatTipusId === 4) &&
						props.data.kerdesValasz.length > 0 &&
						props.data.kerdesValasz.map((item, index) => (
							<ExamAnswerItem key={item.valaszId} index={index} data={item} checked={props.answer && props.answer.valaszId === item.valaszId} onChange={() => props.onAnswerChange(item.valaszId)} />
						))}
				</>
			}
			btmChildren={props.btmChildren}
		/>
	);
}

export function ExamQuestion(props) {
	return (
		<div className="flex flex-col space-y-2">
			<div className="flex flex-row space-x-4 text-white">
				{/* {props.number && <div className='bg-slate-800 shadow-xl rounded-full w-12 h-12 flex items-center justify-center '>
					<div className='text-white leading-none font-bold'>{props.number}</div>
				</div>} */}

				{props.data && (
					<div className="flex-1 flex flex-col space-y-1">
						<div className="text-slate-800 font-extrabold text-sm">{props.data.fosorszam + '.' + props.data.alsorszam}</div>
						<div className="font-semibold" dangerouslySetInnerHTML={{ __html: props.data.feladatTipusId === 3 ? props.data.asszociaciosLeiras.leiras : props.data.leiras }} />
						{props.data.esetleiras && <div className='text-xs' dangerouslySetInnerHTML={{ __html: props.data.esetleiras.esetleiras ? props.data.esetleiras.esetleiras : props.data.esetleiras.esetleirasPlaintext }} />}
					</div>
				)}
			</div>

			{props.data && props.data.feladatTipusId === 2 && (
				<div className="space-y-1">
					{props.data.kerdesElemiValasz.sort(compareFn).map(item => (
						<AnswerText key={item.szam} className="text-sm text-white" char={item.szam} content={item.szoveg} />
					))}
				</div>
			)}

			{props.data && props.data.feladatTipusId === 3 && (
				<div className="space-y-1">
					<AnswerText className="text-sm text-white" content={props.data.leiras} />
				</div>
			)}
		</div>
	);
}

export function ExamAnswerItem(props) {
	return (
		<motion.div
			id={'ANSWER_' + props.index}
			className={'group ' + (!props.display && 'cursor-pointer')}
			onClick={() => props.onChange()}
			style={{ originX: 0.5, originY: 0.5, transformPerspective: 1500 }}
			animate={{ rotateY: 0, opacity: 1 }}
			initial={{ rotateY: 90, opacity: 0 }}
			transition={{ duration: 0.3, delay: props.index * 0.15 }}>

			<div className={'flex flex-row items-center border rounded-xl px-4 py-5 space-x-4 transition ' + (!props.display ? 'hover:bg-sky-700/5 ' : '') + (props.selected ? ' bg-sky-700' : ' bg-white')}>
				{!props.display && <input type="radio" className="w-4 h-4" checked={props.checked} onChange={() => props.onChange()} />}
				<AnswerText
					className={'flex-1 text-sm font-semibold text-slate-800 ' + (!props.display && ' group-hover:text-whitee ') + (props.selected ? ' text-whitee ' : null)}
					hideChar
					char={props.data.betujel}
					content={props.data.szoveg}
				/>
			</div>
		</motion.div>
	);
}

export function AnswerText(props) {
	return (
		<div className={'flex flex-row space-x-1 transition ' + (props.className || '')}>
			{!props.hideChar && props.char && <div className="font-semibold">{props.char}.)</div>}
			<div className="" dangerouslySetInnerHTML={{ __html: props.content }} />
		</div>
	);
}
