import { useState, createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'components/AuthProvider';
import GlassCard, { GlassCardHeading } from 'elements/GlassCard';
import { getTranslation } from 'utils/DictionaryUtils';
import { format } from 'date-fns';
import Countdown from 'react-countdown';

export default function ExamLockedPage(props) {
	const [canBeStarted, setCanBeStarted] = useState(false);
	const [canBeRedirected, setCanBeRedirected] = useState(false);

	const { accessConfig, handleLogout, handleRefresh } = useAuth();
	const navigate = useNavigate();
	const vizsgaKezdesDatum = new Date(accessConfig.vizsgaKezdesDatum);

	useEffect(() => {
		if (canBeStarted)
			handleRefresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canBeStarted]);

	useEffect(() => {
		if (canBeRedirected)
			navigate('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canBeRedirected]);

	const rendererStart = ({ hours, minutes, seconds, completed }) => {
		if (!completed) {
			return (
				<div className='text-center flex flex-col gap-2'>
					<p className='text-center uppercase font-extrabold text-2xl'>{getTranslation('exam-locked-countdown-desc')}</p>
					<span className='text-center font-black text-6xl text-sky-700 uppercase'>{hours ? ((hours < 10 ? ('0' + hours) : hours) + ':') : ''}{minutes < 10 ? ('0' + minutes) : minutes}:{seconds < 10 ? ('0' + seconds) : seconds}</span>
					<p className='text-center'>{getTranslation('exam-locked-countdown-desc-sub')}</p>
				</div>
			);
		}
		return null;
	};

	const rendererRedirect = ({ hours, minutes, seconds, completed }) => {
		if (!completed) {
			return (
				<div className='text-center flex flex-col gap-2'>
					<p className='text-center uppercase font-extrabold text-2xl'>{getTranslation('exam-locked-countdown-redirect')}</p>
					<span className='text-center font-black text-6xl text-sky-700 uppercase'>{seconds < 10 ? ('0' + seconds) : seconds}</span>
					<p className='text-center'>{getTranslation('exam-locked-countdown-redirect-sub')}</p>
				</div>
			);
		}
		return null;
	};

	return (
		<div className='w-full min-h-full flex items-center justify-center'>
			<GlassCard
				className='max-w-3xl'
				topChildren={<GlassCardHeading start={getTranslation('exam-locked')} end={getTranslation('exam-locked-end')} />}
				mdlChildren={
					<>
						<p className='text-center uppercase font-extrabold text-2xl'>{getTranslation('exam-locked-desc')}</p>
						<p className='text-center font-light text-xl'>{format(vizsgaKezdesDatum, getTranslation('date-time-format'), { locale: getTranslation('date-locale') })}</p>

						{!canBeStarted && <Countdown date={vizsgaKezdesDatum} renderer={rendererStart} onComplete={() => setCanBeStarted(true)} />}
						{canBeStarted && <Countdown date={Date.now() + 10000} renderer={rendererRedirect} onComplete={() => setCanBeRedirected(true)} />}

					</>
				}
				btmChildren={<>
					<button className='btn' onClick={() => handleLogout()}>
						{getTranslation('logout')}
					</button>
				</>
				}
			/>
		</div>
	);
}
