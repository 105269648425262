import { useState, createContext, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccessManagementService from 'service/user/AccessManagementService';
import VizsgazoLoginService from 'service/auth/VizsgazoLoginService';

export const AuthContext = createContext(null);

export const useAuth = () => {
	return useContext(AuthContext);
};

export default function AuthProvider(props) {
	const [authChecked, setAuthChecked] = useState(false);
	const [accessConfig, setAccessConfig] = useState(null);
	const [loginError, setLoginError] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		VizsgazoLoginService.isLoggedIn().then(response => {
			if (response) {
				AccessManagementService.isLoggedInAnyone().then(response => {
					setAccessConfig(response);
					setAuthChecked(true);
				});
			} else {
				setAuthChecked(true);
			}
		});
	}, []);

	const handleLogin = (email, password/*, fingerprint*/) => {
		VizsgazoLoginService.login(email, password/*, fingerprint*/).then(response => {
			if (response) {
				setLoginError(false);
				AccessManagementService.isLoggedInAnyone().then(response => {
					setAccessConfig(response);
					// const origin = location.state?.from?.pathname || '/';
					const origin = '/';
					navigate(origin);
				});
			} else {
				setLoginError(true);
			}
		});
	};

	const handleLogout = () => {
		console.log('logout');
		setLoginError(false);
		VizsgazoLoginService.logout().then(() => {
			setAccessConfig(null);
		});
	};

	const handleRefresh = () => {
		AccessManagementService.isLoggedInAnyone().then(response => {
			setAccessConfig(response);
		});
	}

	const value = {
		authChecked,
		accessConfig,
		loginError,
		handleLogin,
		handleLogout,
		handleRefresh
	};

	return <AuthContext.Provider value={value}>{authChecked ? props.children : null}</AuthContext.Provider>;
}
