// import { Link, useNavigate, useParams } from 'react-router-dom';
import {  useState } from 'react';
// import { useAuth } from 'components/AuthProvider';
// import VizsgazoService from 'service/vizsga/VizsgazoService';
import { ExamQuestion, ExamAnswerItem } from 'components/exam/ExamItem';
import ComplaintMessage from './ComplaintMessage';
import GlassCard from 'elements/GlassCard';
import { getTranslation } from 'utils/DictionaryUtils';

const compareFn = (a, b) => {
	const _a = Number.parseInt(a.szam, 10);
	const _b = Number.parseInt(b.szam, 10);
	if (_a < _b) return -1;
	if (_a > _b) return 1;
	return 0;
};

export default function ExamResultItem(props) {
	const helyes = props.data.feladatTipusId !== 3 ? props.data.kerdesValasz.find(item => item.helyes) : props.data.asszociaciosLeirasTetel.find(item => item.helyes);
	const [showComplaint, setShowComplaint] = useState(false);

	return (
		<div className="w-full min-h-full flex items-center justify-center">
			{/* <ComplaintMessage data={props.data} onClose={() => setShowComplaint(false)} /> */}

			{showComplaint && <ComplaintMessage data={props.data} onClose={() => setShowComplaint(false)} />}

			{!showComplaint && (
				<GlassCard
					className="max-w-3xl"
					topChildren={<ExamQuestion number={props.fosorszam} data={props.data} />}
					mdlChildren={
						<div className="flex flex-col space-y-4">
							<SectionTitle title={getTranslation('possible-answers')} />
							<div className="flex flex-col space-y-3">
								{props.data.feladatTipusId !== 3 && props.data.kerdesValasz.length > 0
									? props.data.kerdesValasz.map(item => <ExamAnswerItem key={item.valaszId} data={item} display selected={props.data.vizsgazoValasz === item.valaszId} />)
									: null}
								{props.data.feladatTipusId === 3 && props.data.asszociaciosLeirasTetel.length > 0
									? props.data.asszociaciosLeirasTetel.map(item => <ExamAnswerItem key={item.id} data={item} display selected={props.data.vizsgazoValasz === item.id} />)
									: null}
							</div>

							{helyes && (
								<>
									<SectionTitle title={getTranslation('correct-solution')} />
									<ExamAnswerItem data={helyes} display />
								</>
							)}

							{helyes && helyes.magyarazat && (
								<div className="rounded-xl shadow-xl p-4 flex flex-col space-y-3 border">
									<SectionTitle title={getTranslation('correct-solution-explanation')} />
									<div className="text-sm" dangerouslySetInnerHTML={{ __html: helyes.magyarazat }} />
								</div>
							)}
						</div>
					}
					btmChildren={
						<div className="flex justify-between flex-col sm:flex-row space-x-6">
							<button className="flex-1 btn" onClick={() => props.onClose()}>
								{getTranslation('back')}
							</button>
							{props.complaintAvailable ? (
								<button className="flex-1 btn" onClick={() => setShowComplaint(true)}>
									{getTranslation('complaint-start') + ' ' + getTranslation('complaint-end')}
								</button>
							) : null}
						</div>
					}
				/>
			)}
		</div>
	);
}

function SectionTitle(props) {
	return <div className={'text-sm font-extrabold text-slate-800 uppercase ' + (props.className || '')}>{props.title}</div>;
}
