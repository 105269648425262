import { motion } from 'framer-motion';

export default function GlassCard(props) {
    return (
        <motion.div
            className={'w-full flex flex-col -space-y-6 p-4 ' + (props.className || '')}
            animate={{ scale: 1, opacity: 1 }}
            initial={{ scale: 0.5, opacity: 0 }}
            transition={{ delay: 0.5 }}>

            {props.topChildren && <motion.div
                className={'card glass top flex flex-col px-6 pt-6 pb-12 mx-6 order-1 ' + (props.topClassName || '')}
                animate={{ translateY: 0 }}
                initial={{ translateY: 100 }}
                transition={{ delay: 0.65 }}>

                {props.topChildren}

            </motion.div>}

            {props.mdlChildren && <div className={'card flex flex-col p-6 space-y-3 order-2 z-10 ' + (props.mdlClassName || '')}>

                {props.mdlChildren}

            </div>}

            {props.btmChildren && <motion.div
                className={'card glass btm flex flex-col p-6 px-6 pt-12 pb-6 mx-6 space-y-2 order-3 ' + (props.btmClassName || '')}
                animate={{ translateY: 0 }}
                initial={{ translateY: -100 }}
                transition={{ delay: 0.65 }}>

                {props.btmChildren}

            </motion.div>}

        </motion.div>
    )
}

export function GlassCardHeading(props) {
    return (
        <h1 className='w-full text-white text-center uppercase'>{props.start} <span className='text-slate-800 font-extrabold'>{props.end}</span></h1>
    );
}