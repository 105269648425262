import IdentifiableDto from '../IdentifiableDto';

export class ExamQuestionItemAnswerDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.betujel = null;
		/** @type {string} */
		this.szoveg = null;
		/** @type {number} */
		this.vizsgafeladatId = null;
		/** @type {string} */
		this.szovegPlaintext = null;
		/** @type {number} */
		this.valaszId = null;
		/** @type {boolean} */
		this.helyes = null;
		/** @type {string} */
		this.magyarazat = null;
		/** @type {string} */
		this.magyarazatPlaintext = null;
	}

	/**
	 * @typedef {Object} _ExamQuestionItemAnswerDtoProps
	 * @property {string} betujel
	 * @property {string} szoveg
	 * @property {number} vizsgafeladatId
	 * @property {string} szovegPlaintext
	 * @property {number} valaszId
	 * @property {boolean} helyes
	 * @property {string} magyarazat
	 * @property {string} magyarazatPlaintext
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ExamQuestionItemAnswerDtoProps} ExamQuestionItemAnswerDtoProps
	 */
	/**
	 *
	 * @param {ExamQuestionItemAnswerDtoProps} props
	 * @returns {ExamQuestionItemAnswerDto}
	 */
	setup(props) {
		super.setup(props);
		this.betujel = props.betujel;
		this.szoveg = props.szoveg;
		this.vizsgafeladatId = props.vizsgafeladatId;
		this.szovegPlaintext = props.szovegPlaintext;
		this.valaszId = props.valaszId;
		this.helyes = props.helyes;
		this.magyarazat = props.magyarazat;
		this.magyarazatPlaintext = props.magyarazatPlaintext;
		return this;
	}
}

export default ExamQuestionItemAnswerDto;
