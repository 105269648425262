import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// import DynamicIcon from '@/elements/DynamicIcon.js';

export default function Modal(props) {
    return (
        <ModalBackground onBackgroundClick={() => props.onClose()}>

            <motion.div initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} className='min-w-full max-w-sm m-4  card shadow-xl overflow-hidden'>

                {props.children}

            </motion.div>

        </ModalBackground>
    );
}

export function ModalBackground(props) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-50 w-full h-full'>
            <div className='relative w-full h-full flex items-center justify-center'>
                <div className='absolute top-0 left-0 w-full h-full bg-opacity-30 bg-black backdrop-filter backdrop-grayscale-50 z-0' onClick={() => props.onBackgroundClick()} />
                <div className='z-10'>
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}

export function ModalHeader(props) {
    return (
        <div className='w-full px-4 sm:px-6 py-6 bg-tv-red text-lg sm:text-xl font-bold shadow-xl bg-no-repeat bg-bottom sm:bg-top bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}>
            {props.children}
        </div>
    );
}

export function ModalContent(props) {
    return (
        <div className='w-full flex flex-col space-y-4 p-4 sm:p-6'>
            {props.children}
        </div>
    );
}

export function ModalButton(props) {
    // const className = 'flex flex-row gap-2 items-center justify-center bg-tv-red dark:bg-tv-purple transition hover:bg-tv-purple dark:hover:bg-tv-yellow p-4 text-white dark:hover:text-tv-gray-darkest text-sm font-semibold ' + (props.className || '')
    const className = 'btn' + (props.alt ? ' alt' : '');

    if (props.to)
        return (<Link id={props.id || null} className={className} to={props.to} onClick={() => props.onClick()}><ModalButtonContent {...props} /></Link>)
    else
        return (<button id={props.id || null} className={className} onClick={() => props.onClick()}><ModalButtonContent {...props} /></button>);
}

function ModalButtonContent(props) {
    return (
        <>
            {/* {props.icon && <DynamicIcon className={'text-sm'} iconName={props.icon} />} */}
            {props.label}
        </>
    );
};
