export default function BackgroundArtifact(props) {
    return (
        <div className='fixed w-screen h-screen opacity-30'>

            <div className='w-full h-full relative flex items-center'>

                <img className='fixed w-screen h-screen top-0 -left-48 opacity-50' src={'/assets/images/fader.png'} alt='' />
                <img className='fixed -left-96' src={'/assets/images/big-cross.png'} alt='' />

            </div>

        </div>
    );
}