import IdentifiableDto from '../IdentifiableDto';
import ExamResultItemDto from './ExamResultItemDto';

export class ExamResultDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {number} */
		this.erdemjegy = null;
		/** @type {string} */
		this.errorMessage = null;
		/** @type {number} */
		this.maxPontszam = null;
		/** @type {number} */
		this.pontszam = null;
		/** @type {boolean} */
		this.reklamacio = null;
		/** @type {Date} */
		this.reklamacioZaras = null;
		/** @type {ExamResultItemDto[]} */
		this.jelolesek = null;
	}

	/**
	 * @typedef {Object} _ExamResultDtoProps
	 * @property {number} erdemjegy
	 * @property {string} errorMessage
	 * @property {number} maxPontszam
	 * @property {number} pontszam
	 * @property {boolean} reklamacio
	 * @property {Date} reklamacioZaras
	 * @property {ExamResultItemDto[]} jelolesek
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ExamResultDtoProps} ExamResultDtoProps
	 */
	/**
	 *
	 * @param {ExamResultDtoProps} props
	 * @returns {ExamResultDto}
	 */
	setup(props) {
		super.setup(props);
		this.erdemjegy = props.erdemjegy;
		this.errorMessage = props.errorMessage;
		this.maxPontszam = props.maxPontszam;
		this.pontszam = props.pontszam;
		this.reklamacio = props.reklamacio;
		this.reklamacioZaras = props.reklamacioZaras !== null ? new Date(props.reklamacioZaras) : null;
		this.jelolesek = props.jelolesek !== null ? props.jelolesek.map(d => new ExamResultItemDto().setup(d)) : null;
		return this;
	}
}

export default ExamResultDto;
