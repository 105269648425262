import React from 'react';

import Modal, { ModalButton, ModalHeader, ModalContent } from 'elements/modal/Modal';

export default function ModalDialog(props) {
    return (
        <Modal {...props}>

            <ModalHeader>{props.title}</ModalHeader>

            <ModalContent>

                <div className='text-sm content' dangerouslySetInnerHTML={{ __html: props.content }} />

                <div className='flex flex-col space-y-2'>

                    {props.positiveButtonLabel && <ModalButton id={props.positiveButtonId} label={props.positiveButtonLabel} onClick={() => props.onPositiveClick()} />}
                    {props.negativeButtonLabel && <ModalButton id={props.negativeButtonId} alt label={props.negativeButtonLabel} onClick={() => props.onNegativeClick()} />}

                </div>

            </ModalContent>

        </Modal>
    );
}