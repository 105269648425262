import DynamicIcon from 'elements/DynamicIcon.js';

export default function QuestionSummaryItem(props) {
    let mainClassName = 'ring-sky-700';
    let mainOrbClassName = (props.highlight ? 'bg-sky-700 group-hover:bg-sky-700' : 'bg-slate-800 group-hover:bg-sky-700');
    let checkOrbClassName = 'text-slate-800';

    if (props.okVisible) {
        mainClassName = props.ok ? 'ring-green-700' : 'ring-red-700';
        mainOrbClassName = props.ok ? 'bg-green-700 group-hover:bg-green-600' : 'bg-red-700 group-hover:bg-red-600';
        checkOrbClassName = props.ok ? 'text-green-700' : 'text-red-700';
    }

    return (
        <div className={'cursor-pointer flex flex-row items-center p-1.5 rounded-full bg-white border shadow-lg hover:ring-4 ring-offset-4 transition group ' + (mainClassName + ' ') + (props.className || '')} onClick={() => props.onClick()}>

            <Orb className={'text-white ' + mainOrbClassName}>{props.number}</Orb>

            <div className='flex-1 text-xs tracking-wide text-center font-extrabold text-slate-800'>{props.name}</div>

            {props.checkVisible && <Orb className={'border ' + checkOrbClassName}>
                <DynamicIcon className='text-sm' iconName={props.checked ? 'FaCheck' : (props.okVisible ? 'FaTimes' : 'FaMinus')} />
            </Orb>}

        </div>
    );
}

function Orb(props) {
    return (
        <div className={'flex items-center justify-center rounded-full w-10 h-10 text-xs font-extrabold shadow-lg transition ' + (props.className || '')}>{props.children}</div>
    )
}