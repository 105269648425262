import { useEffect, useState } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useLocalStore } from 'localstore/LocalStoreProvider';
import { useTest } from 'test/TestProvider';
import LoginPage from './pages/LoginPage.js';
import StartPage from './pages/StartPage.js';
import NewsPage from './pages/NewsPage.js';
import ExamResultPage from './pages/ExamResultPage.js';
import StatusBar from './components/StatusBar.js';
import Header from 'components/Header';
import AuthProvider from 'components/AuthProvider';
import ProtectedRoute from 'components/ProtectedRoute';
import ExamPage from 'pages/ExamPage';
import ExamProvider from 'components/exam/ExamProvider';
import NbossErrorHandler from 'components/NbossErrorHandler';
import BackgroundArtifact from 'elements/BackgroundArtifact';
import ExamLockedPage from './pages/ExamLockedPage';
import TestProvider from 'test/TestProvider';
import LocalStoreProvider from 'localstore/LocalStoreProvider';
import ModalDialog from 'elements/modal/ModalDialog';
import TestFloater from 'test/TestFloater';
// import LostDataSender from 'components/LostDataSender';
import { getTranslation } from 'utils/DictionaryUtils.js';

export default function App() {
	return (
		<Router>
			<AuthProvider>
				<LocalStoreProvider>
					<TestProvider>
						<NbossErrorHandler>
							<ExamProvider>
								<InsideProviders />
							</ExamProvider>
						</NbossErrorHandler>
					</TestProvider>
				</LocalStoreProvider>
			</AuthProvider>
		</Router>
	);
}

function InsideProviders(props) {
	const [unloadModalVisible, setUnloadModalVisible] = useState(false);

	const location = useLocation();

	const { queue } = useLocalStore();

	const { testEnabled } = useTest();

	const handleUnload = (event) => {
		if (location && location.pathname && location.pathname === '/login') {

		} else {
			if (queue && queue.length > 0) setUnloadModalVisible(true);
			event.preventDefault();
		}
	};

	return (
		<Beforeunload onBeforeunload={(event) => { handleUnload(event); }}>

			<div className='relative flex flex-col min-h-screen'>

				<Header />

				<BackgroundArtifact />

				<div className='flex-1 flex w-full mt-16 mb-6 z-10'>
					<Routes>
						<Route path='/login' element={<LoginPage />} />
						<Route
							path='/exam'
							element={<ProtectedRoute><ExamPage /></ProtectedRoute>}>
							<Route path=':page' element={<ProtectedRoute><ExamPage /></ProtectedRoute>} />
						</Route>
						<Route path='/exam-result' element={<ProtectedRoute><ExamResultPage /></ProtectedRoute>} />
						<Route path='/news' element={<ProtectedRoute><NewsPage /></ProtectedRoute>} />
						<Route path='/locked' element={<ProtectedRoute><ExamLockedPage /></ProtectedRoute>} />
						<Route path='/' element={<ProtectedRoute><StartPage /></ProtectedRoute>} />
					</Routes>
				</div>

				<StatusBar className='fixed bottom-0' />

			</div>

			{unloadModalVisible && <ModalDialog
				title={getTranslation('local-storage-modal-title')}
				content={getTranslation('local-storage-modal-content')}
				positiveButtonLabel={getTranslation('back')}
				onPositiveClick={() => { setUnloadModalVisible(false); }}
				onClose={() => { setUnloadModalVisible(false); }} />}

			{testEnabled && <TestFloater />}
			{/* <LostDataSender /> */}

		</Beforeunload>
	);
}