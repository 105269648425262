import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

export default function ProtectedRoute(props) {
	const { accessConfig } = useAuth();
	const location = useLocation();

	if (accessConfig === null) {
		return <Navigate to="/login" replace state={{ from: location }} />;
	}

	return props.children;
}
