import axios from 'axios';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAuth } from 'components/AuthProvider';
import GlassCard, { GlassCardHeading } from 'elements/GlassCard';
import { getTranslation } from 'utils/DictionaryUtils';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
// import TimeoutButton from 'elements/TimeoutButton';
import { useTest } from 'test/TestProvider';
import ModalDialog from 'elements/modal/ModalDialog';

import VizsgazoService from 'service/vizsga/VizsgazoService';

const schema = yup.object().shape({
	name: yup.string().required(getTranslation('name-needed')),
	bornDate: yup.string().required(getTranslation('born-date-needed')).typeError(getTranslation('born-date-valid')),
	mothersName: yup.string().required(getTranslation('mothers-name-needed')),
	email: yup.string().required(getTranslation('email-needed')),
	gdprAccept: yup.bool().oneOf([true], getTranslation('gdpr-needed'))
});

const defaultValues = { name: '', mothersName: '', bornDate: '', email: '', gdprAccept: false };

export default function StartPage(props) {
	const [postAuth, setPostAuth] = useState(false);
	const [postAuthError, setPostAuthError] = useState(null);
	const [sendErrorModalVisible, setSendErrorModalVisible] = useState(null);

	const [nameDisabled, setNameDisabled] = useState(false);
	const [bornDateDisabled, setBornDateDisabled] = useState(false);
	const [adatlapAnyjaNeveDisabled, setAdatlapAnyjaNeveDisabled] = useState(false);
	const [emailDisabled, setEmailDisabled] = useState(false);
	const [gdprAcceptDisabled, setGdprAcceptDisabled] = useState(false);

	const { accessConfig } = useAuth();

	const { setStartPageState } = useTest();

	useEffect(() => {
		setStartPageState(postAuth ? 'POST_AUTH' : 'PRE_AUTH');
	}, [postAuth]);

	const navigate = useNavigate();

	const { control, setValue, formState, handleSubmit, reset, trigger, setError, register } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	useEffect(() => {
		if (accessConfig) {
			if (accessConfig.adatlapNev) setNameDisabled(true);
			if (accessConfig.adatlapSzuletesiDatum) setBornDateDisabled(true);
			if (accessConfig.adatlapAnyjaNeve) setAdatlapAnyjaNeveDisabled(true);
			if (accessConfig.email) setEmailDisabled(true);
			if (accessConfig.gdprAccept) setGdprAcceptDisabled(true);
		}
		setValue('name', (accessConfig && accessConfig.adatlapNev) ? accessConfig.adatlapNev : '', { shouldDirty: true, shouldValidate: true });
		setValue('bornDate', (accessConfig && accessConfig.adatlapSzuletesiDatum) ? format(new Date(accessConfig.adatlapSzuletesiDatum), "yyyy-MM-dd") : '', { shouldDirty: true, shouldValidate: true });
		setValue('mothersName', (accessConfig && accessConfig.adatlapAnyjaNeve) ? accessConfig.adatlapAnyjaNeve : '', { shouldDirty: true, shouldValidate: true });
		setValue('email', (accessConfig && accessConfig.email) ? accessConfig.email : '', { shouldDirty: true, shouldValidate: true });
		setValue('gdprAccept', (accessConfig && accessConfig.gdprAccept) ? accessConfig.gdprAccept : false, { shouldDirty: true, shouldValidate: true });
	}, [reset, setValue, trigger, accessConfig]);

	function onSubmit(model) {
		handlePostAuth(model);
	}

	if (accessConfig.lockedOnlineExam) { return <Navigate to="/exam-result" />; }
	if (!accessConfig.vizsgaKiadhato) { return <Navigate to="/locked" />; }

	const handlePostAuth = (data) => {
		const basePath = `/${process.env.REACT_APP_API_NAME}/methods/`;
		const baseUrl = `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT.length > 0 ? `:${process.env.REACT_APP_API_PORT}` : ``)}${basePath}`;

		axios.post(baseUrl + 'vizsgazoControl/storeExamineeData', { input: data }).then(function (response) {
			if (response.data && response.data.successful) {
				setPostAuth(true);
				setPostAuthError(null);
			} else {
				setPostAuth(false);
				setPostAuthError(getTranslation('generic-error'));
			}
		}).catch(function (error) {
			console.log('Send error.');
			console.log(error);
			setSendErrorModalVisible(true);
		});

		// VizsgazoService.storeExamineeData(data).then(response => {
		// 	if (response && response.successful) {
		// 		setPostAuth(true);
		// 		setPostAuthError(null);
		// 	} else {
		// 		setPostAuth(false);
		// 		setPostAuthError(getTranslation('generic-error'));
		// 	}
		// });
	};

	return (
		<div className="w-full min-h-full flex items-center justify-center">

			{!postAuth && <GlassCard
				className="max-w-3xl"
				topChildren={
					<GlassCardHeading start={getTranslation('post-auth-title-start')} end={getTranslation('post-auth-title-end')} />
				}
				mdlChildren={
					<>
						<div className="text-center" dangerouslySetInnerHTML={{ __html: getTranslation('post-auth-description') }} />

						<form className='flex flex-col space-y-3 shadow-xl p-6 rounded-xl border' onSubmit={handleSubmit(onSubmit)}>

							<div className='flex flex-col -space-y-2'>
								<input className='generic-input z-10' disabled={nameDisabled} placeholder={getTranslation('name')} {...register('name')} />
								{errors.name && <div className='generic-input-error-message'>{errors.name.message}</div>}
							</div>
							<div className='flex flex-col -space-y-2'>
								<div className='text-xs font-bold text-center pt-2 pb-4 rounded-t-xl bg-slate-50 border-t border-l border-r text-slate-800'>Születési dátum</div>
								<input className='generic-input z-10' disabled={bornDateDisabled} placeholder={getTranslation('birth-date')} type='date' {...register('bornDate')} />
								{errors.bornDate && <div className='generic-input-error-message'>{errors.bornDate.message}</div>}
							</div>
							<div className='flex flex-col -space-y-2'>
								<input className='generic-input z-10' disabled={adatlapAnyjaNeveDisabled} placeholder={getTranslation('mothers-name')}  {...register('mothersName')} />
								{errors.mothersName && <div className='generic-input-error-message'>{errors.mothersName.message}</div>}
							</div>
							<div className='flex flex-col -space-y-2'>
								<input className='generic-input z-10' disabled={emailDisabled} placeholder={getTranslation('email')}  {...register('email')} />
								{errors.email && <div className='generic-input-error-message'>{errors.email.message}</div>}
							</div>
							<div className='flex flex-col space-y-2'>
								<div className='w-full flex space-x-4'>
									<input id='GDPR_CHECKBOX' type="checkbox" className='generic-input z-10' disabled={gdprAcceptDisabled}   {...register('gdprAccept')} />
									<div className='text-xs' dangerouslySetInnerHTML={{ __html: getTranslation('gdpr-label') }} />
								</div>
								{errors.gdprAccept && <div className='generic-input-error-message border-t rounded-t-xl pt-2'>{errors.gdprAccept.message}</div>}
							</div>
						</form>
					</>
				}
				btmChildren={
					<form className='w-full flex flex-col space-y-2' onSubmit={handleSubmit(onSubmit)}>
						<button id='PRE_AUTH_SEND' className="btn" disabled={_.isEmpty(dirtyFields) || !isValid} type='submit'>{getTranslation('start-exam')}</button>
						{/* <TimeoutButton className='w-full btn' type='submit' label={getTranslation('start-exam')} disabled={_.isEmpty(dirtyFields) || !isValid} /> */}
						{postAuthError && <div className='generic-error-message'>{getTranslation('login-error')}</div>}
					</form>
				} />}


			{postAuth && <GlassCard
				className="max-w-3xl"
				topChildren={
					<GlassCardHeading start={getTranslation('welcome-title')} end={getTranslation('on-exam')} />
				}
				mdlChildren={<div className="text-center" dangerouslySetInnerHTML={{ __html: getTranslation('welcome-text') }} />}
				btmChildren={
					// <TimeoutButton className='w-full btn' type='submit' label={getTranslation('start-exam')} onClick={() => { navigate('/exam') }} />
					<Link id='POST_AUTH_SEND' className="btn" to="/exam">
						{getTranslation('start-exam')}
					</Link>
				} />}

			{sendErrorModalVisible && (<ModalDialog
				title={getTranslation('generic-close-modal-title')}
				content={getTranslation('local-storage-close-modal-content')}
				positiveButtonId='START_MODAL_ERROR_CLOSE'
				positiveButtonLabel={getTranslation('back')}
				onPositiveClick={() => { setSendErrorModalVisible(false); }}
				onClose={() => { setSendErrorModalVisible(false); }} />)}


		</div>
	);
}
