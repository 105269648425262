import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'components/AuthProvider';
import ExamResultItem from 'components/result/ExamResultItem';
import Loader from 'elements/Loader';
import GlassCard from 'elements/GlassCard';
import QuestionSummaryItem from 'elements/QuestionSummaryItem';
import { getTranslation } from 'utils/DictionaryUtils';
import VizsgazoService from 'service/vizsga/VizsgazoService';

export default function ExamPage(props) {
	const { accessConfig } = useAuth();
	const [data, setData] = useState(null);
	const [examResult, setExamResult] = useState(null);
	const [question, setQuestion] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		VizsgazoService.getOnlineExamQuestionListWithResultNew().then(response => {
			setData(response);
			VizsgazoService.getExamResult().then(response => {
				setExamResult(response);
			});
		});
	}, []);

	if (!accessConfig.lockedOnlineExam) {
		navigate('/');
		return <></>;
	}

	return (
		<div className="w-full min-h-full flex items-center justify-center">
			{(!data || !examResult) && <Loader />}

			{data && examResult && question && examResult.errorMessage === null && (
				<ExamResultItem data={data.questions.find(item => item.kerdesId === question)} onClose={() => setQuestion(null)} complaintAvailable={examResult.reklamacio} />
			)}

			{data && examResult && !question && examResult.errorMessage === null && (
				<GlassCard
					className="max-w-7xl"
					topChildren={
						<div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-6">
							<SummaryItem className="w-full sm:w-1/2" label={getTranslation('results-overview')} value={examResult.vizsgaNeve} />

							<div className="flex-1 flex flex-row space-x-4">
								<SummaryItem className="flex-1" label={getTranslation('results-max-label')} value={examResult.maxPontszam} />
								<SummaryItem className="flex-1" label={getTranslation('results-achieved-label')} value={examResult.pontszam} />
								<SummaryItem className="flex-1" label={getTranslation('results-grade-label')} value={examResult.erdemjegy} />
							</div>
						</div>
					}
					mdlClassName="p-4"
					mdlChildren={
						<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
							{examResult.jelolesek.map(item => (
								<QuestionSummaryItem
									key={item.kerdesId}
									className="m-2"
									number={parseInt(item.cSorszam)}
									name={item.sorszam}
									checkVisible
									checked={item.helyes}
									okVisible
									ok={item.helyes}
									onClick={() => setQuestion(item.kerdesId)}
								/>
							))}
						</div>
					}
				/>
			)}

			{data && examResult && examResult.errorMessage !== null && (
				<GlassCard
					className="max-w-7xl"
					topChildren={
						<div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-6">
							<SummaryItem className="w-full" label={getTranslation('results-overview')} value={examResult.vizsgaNeve} />
						</div>
					}
					mdlClassName="p-4"
					mdlChildren={<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">{examResult.errorMessage}</div>}
				/>
			)}
		</div>
	);
}

function SummaryItem(props) {
	return (
		<div className={'rounded-xl bg-white shadow-xl flex flex-col justify-center p-6 space-y-1 ' + (props.className || '')}>
			<div className="text-xs">{props.label}</div>
			<div className="text-3xl font-bold">{props.value || '-'}</div>
		</div>
	);
}
