import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import getBrowserFingerprint from 'get-browser-fingerprint';
import GlassCard from 'elements/GlassCard';
import { AuthContext } from 'components/AuthProvider';
import { setLanguage, getTranslation } from 'utils/DictionaryUtils';

const schema = yup.object().shape({
	email: yup
		.string() /*.email('Email formátuma nem megfelelő.')*/
		.required(getTranslation('username-needed')),
	password: yup.string().required(getTranslation('enter-password')).min(4, getTranslation('password-requirements'))
});

const defaultValues = { email: '', password: '' };

export default function LoginPage(props) {
	const [fingerprint, setFingerprint] = useState('');
	const { handleLogin, loginError } = useContext(AuthContext);

	const { control, setValue, formState, handleSubmit, reset, trigger, setError, register } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	const getAvailableUserData = () => {
		const fingerprint = getBrowserFingerprint();

		var _navigator = {};
		for (var i in navigator) _navigator[i] = navigator[i];

		return { fingerprint, navigator: _navigator }
	};

	useEffect(() => {
		setFingerprint(JSON.stringify(getAvailableUserData()));
	}, []);

	useEffect(() => {
		setValue('email', '', { shouldDirty: true, shouldValidate: true });
		setValue('password', '', { shouldDirty: true, shouldValidate: true });
	}, [reset, setValue, trigger]);

	function onSubmit(model) {
		handleLogin(model.email, model.password, fingerprint);
	}

	return (
		<div className='w-full min-h-full flex items-center justify-center'>

			<GlassCard
				className='max-w-lg'
				topClassName='sm:flex-row items-center justify-center sm:justify-end text-white'
				topChildren={<>
					<div className='flex flex-col items-left text-xl tracking-wide text-center sm:text-right pb-2 sm:pb-0 pr-0 sm:pr-4'>
						<div className='font-bold uppercase text-slate-800'>{getTranslation('finalexamcenter')}</div>
						<div className='font-bold text-sm tracking-wider '>{getTranslation('login')}</div>
					</div>

					<div className='flex flex-col space-y-2 pt-2 sm:pt-0 pl-0 sm:pl-4 border-t sm:border-t-0 border-l-0 sm:border-l'>
						<div className='font-lightt tracking-wide text-xs'>Available languages</div>
						<div className=' flex flex-row space-x-2'>
							<LanguageButton icon='/assets/images/language/hu.png' language='hu-HU' />
							<LanguageButton icon='/assets/images/language/en.png' language='en-GB' />
							<LanguageButton icon='/assets/images/language/de.png' language='de-DE' />
						</div>
					</div>
				</>}
				mdlChildren={<>
					<div className='flex flex-col -space-y-2'>
						<input id='LOGIN_USERNAME' className='generic-input z-10' placeholder={getTranslation('user-name')} {...register('email')} />
						{errors.email && <div className='generic-input-error-message'>{errors.email.message}</div>}
					</div>

					<div className='flex flex-col -space-y-2'>
						<input id='LOGIN_PASSWORD' type='password' className='generic-input z-10' placeholder={getTranslation('password')} {...register('password')} />
						{errors.password && <div className='generic-input-error-message'>{errors.password.message}</div>}
					</div>
				</>}
				btmChildren={<form  id='LOGIN_FORM' className='flex flex-col space-y-2' onSubmit={handleSubmit(onSubmit)}>
					{/* <TimeoutButton className='w-full btn' type='submit' label={getTranslation('login')} disabled={_.isEmpty(dirtyFields) || !isValid} /> */}
					<button id='LOGIN_BUTTON' type='submit' className='w-full btn' disabled={_.isEmpty(dirtyFields) || !isValid}>{getTranslation('login')}</button>
					{loginError && <div className='generic-error-message'>{getTranslation('login-error')}</div>}
				</form>} />

		</div>
	);
}

function LanguageButton(props) {
	return <img src={props.icon} alt='' className='w-8 h-6 cursor-pointer hover:scale-90 transition' onClick={() => setLanguage(props.language)} />;
}
