import nBossConnection from 'service/nBossConnection';

class VizsgazoLoginService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'vizsgazoLogin';
	}

	/**
	 * @param {string} email
	 * @param {string} password
	 * @return {Promise<boolean>}
	 */
	login(email, password/*, fingerprint*/) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/login`, {
					email,
					password,
					/*fingerprint*/
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<any>}
	 */
	logout() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/logout`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<boolean>}
	 */
	isLoggedIn() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/isLoggedIn`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new VizsgazoLoginService();
