import IdentifiableDto from '../IdentifiableDto';
import ExamQuestionItemAnswerDto from './ExamQuestionItemAnswerDto';
import ExamQuestionItemPossibleAnswerDto from './ExamQuestionItemPossibleAnswer';

export class ExamQuestionItemDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.kod = null;
		/** @type {string} */
		this.csorszam = null;
		/** @type {ExamQuestionItemAnswerDto[]} */
		this.kerdesValasz = null;
		/** @type {string} */
		this.leiras = null;
		/** @type {number} */
		this.feladatTipusId = null;
		/** @type {number} */
		this.kerdesId = null;
		/** @type {number} */
		this.vizsgafeladatId = null;
		/** @type {number} */
		this.fosorszam = null;
		/** @type {Object[]} */
		this.asszociaciosLeirasTetel = null;
		/** @type {Object} */
		this.esetleiras = null;
		/** @type {string} */
		this.leirasPlaintext = null;
		/** @type {Object} */
		this.asszociaciosLeiras = null;
		/** @type {ExamQuestionItemPossibleAnswerDto[]} */
		this.kerdesElemiValasz = null;
		/** @type {number} */
		this.alsorszam = null;
		/** @type {number} */
		this.vizsgazoValasz = null;
	}

	/**
	 * @typedef {Object} _ExamQuestionItemDtoProps
	 * @property {string} kod
	 * @property {string} csorszam
	 * @property {ExamQuestionItemAnswerDto[]} kerdesValasz
	 * @property {string} leiras
	 * @property {number} feladatTipusId
	 * @property {number} kerdesId
	 * @property {number} vizsgafeladatId
	 * @property {number} fosorszam
	 * @property {Object[]} asszociaciosLeirasTetel
	 * @property {Object} esetLeiras
	 * @property {string} leirasPlaintext
	 * @property {Object} asszociaciosLeiras
	 * @property {ExamQuestionItemPossibleAnswerDto[]} kerdesElemiValasz
	 * @property {number} alsorszam
	 * @property {number} vizsgazoValasz
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ExamQuestionItemDtoProps} ExamQuestionItemDtoProps
	 */
	/**
	 *
	 * @param {ExamQuestionItemDtoProps} props
	 * @returns {ExamQuestionItemDto}
	 */
	setup(props) {
		super.setup(props);
		this.kod = props.kod;
		this.csorszam = props.csorszam;
		this.kerdesValasz = props.kerdesValasz !== null ? props.kerdesValasz.map(d => new ExamQuestionItemAnswerDto().setup(d)) : null;
		this.leiras = props.leiras;
		this.feladatTipusId = props.feladatTipusId;
		this.kerdesId = props.kerdesId;
		this.vizsgafeladatId = props.vizsgafeladatId;
		this.fosorszam = props.fosorszam;
		this.asszociaciosLeirasTetel = props.asszociaciosLeirasTetel;
		this.esetleiras = props.esetleiras;
		this.leirasPlaintext = props.leirasPlaintext;
		this.asszociaciosLeiras = props.asszociaciosLeiras;
		this.kerdesElemiValasz = props.kerdesElemiValasz !== null ? props.kerdesElemiValasz.map(d => new ExamQuestionItemPossibleAnswerDto().setup(d)) : null;
		this.alsorszam = props.alsorszam;
		this.vizsgazoValasz = props.vizsgazoValasz;
		return this;
	}
}

export default ExamQuestionItemDto;
