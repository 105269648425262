import { motion } from 'framer-motion';
import { useState } from 'react';
import QuestionSummaryItem from 'elements/QuestionSummaryItem';
import { getTranslation } from 'utils/DictionaryUtils';

export default function QuestionListBar(props) {
    const [filter, setFilter] = useState(false);

    const hasAnswer = (item, index) => {
        return props.answers.find(item => item.vizsgafeladatId === props.questions[index].vizsgafeladatId) ? true : false;
    };

    return (
        <motion.div
            className='fixed w-64 h-full bg-white shadow-xl right-0 top-0 pt-16 pb-6 z-50'
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            transition={{ duration: .3, type: 'easeIn' }}
            variants={{ visible: { translateX: 0 }, hidden: { translateX: '100%' } }}>

            <div className='h-full flex flex-col'>

                <div className='flex flex-row overflow-hidden border-b'>
                    <FilterButton active={!filter} label={getTranslation('exam-summary-all')} subLabel={props.questions.length + ' ' + getTranslation('question')} onClick={() => setFilter(false)} />
                    <FilterButton active={filter} label={getTranslation('exam-summary-remaining') + ' '} subLabel={(props.questions.length - props.answers.length) + ' ' + getTranslation('question')} onClick={() => setFilter(true)} />
                </div>

                <div className='flex-1 flex flex-col space-y-4 px-4 py-4 overflow-y-scroll'>
                    {props.questions.map((item, index) => (

                        (!filter || (filter && !hasAnswer(item, index))) && <QuestionSummaryItem
                            key={item.csorszam}
                            number={index + 1}
                            name={item.fosorszam + "." + item.alsorszam}
                            highlight={props.currentIndex === index}
                            checkVisible
                            checked={hasAnswer(item, index)}
                            onClick={() => props.onItemClick(index)} />

                    ))}
                </div>

                <button onClick={props.onClose} className='p-4 bg-sky-700 hover:bg-slate-800 transition-colors text-sm text-white font-bold border-t'>Bezárás</button>

            </div>

        </motion.div>
    )
}

function FilterButton(props) {
    return (
        <button className={'flex-1 py-2.5 flex flex-col space-y-0.5 items-center justify-center transition ' + (props.active ? 'bg-sky-700 shadow-inner' : '')} onClick={() => props.onClick()}>
            <div className={'uppercase text-sm font-bold transition ' + (props.active ? 'text-white' : 'text-slate-800')}>{props.label}</div>
            <div className={'lowercase text-xs font-semibold transition ' + (props.active ? 'text-white' : 'text-slate-800')}>{props.subLabel}</div>
        </button>
    );
}