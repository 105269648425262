import { useState, createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import { useAuth } from 'components/AuthProvider';

const TIME = 2000;

export const TestContext = createContext(null);

export const useTest = () => {
    return useContext(TestContext);
};

export default function TestProvider(props) {
    const [testEnabled, setTestEnabled] = useState(true);
    const [testInProgress, setTestInProgress] = useState(false);
    const [messageList, setMessageList] = useState([]);

    const [startPageState, setStartPageState] = useState(null);

    const location = useLocation();

    const globalTimer = useRef(null);
    const genericTimer = useRef(null);
    const searchTimer = useRef(null);
    // const interval = useRef(null);

    const { handleLogin, handleLogout } = useAuth();

    useEffect(() => {
        connectToWebSocket();
    }, []);

    useEffect(() => {
        addMessage(testInProgress ? 'Test started.' : 'Test stopped.');
    }, [testInProgress]);

    useEffect(() => {
        if (testInProgress) startTest();
        else stopTest();
    }, [testInProgress, location, startPageState]);

    const connectToWebSocket = () => {
        const socket = new WebSocket("wss://online.zarovizsga.hu/zvbonline-service/test")

        // Connection opened
        socket.onopen = (event) => {
            // socket.send("Connection established")
        };

        socket.onclose = function (e) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function () {
                connectToWebSocket();
            }, TIME);
        };

        socket.onmessage = (event) => {
            console.log("Message from server ", event.data);

            let data = null;
            if (event.data) data = JSON.parse(event.data);

            if (data) {
                if (data.type === 'LOGIN') {
                    let user = data.data.user;
                    let pass = data.data.pass;

                    doLoginTest(user, pass);

                    clearTimeout(globalTimer.current);
                    globalTimer.current = setTimeout(() => {
                        setTestInProgress(true);
                    }, TIME);
                } else if (data.type === 'START') {
                    setTestInProgress(true);
                } else if (data.type === 'STOP') {
                    setTestInProgress(false);
                } else if (data.type === 'PAUSE') {
                    setTestInProgress(!testInProgress);
                } else if (data.type === 'PAUSE') {
                    setTestInProgress(!testInProgress);
                } else if (data.type === 'LOGOUT') {
                    handleLogout();
                }
            }
        };
    };

    const clearTimeouts = () => {
        // clearTimeout(globalTimer.current);
        clearTimeout(genericTimer.current);
        clearTimeout(searchTimer.current);
    };

    const startTest = () => {
        clearTimeouts();
        // if (location.pathname.indexOf('/login') > -1) doLoginTest('', '');
        if (location.pathname.indexOf('/') > -1) doPreExamTest();
        if (location.pathname.indexOf('/exam') > -1) doExamTest();
    };

    const stopTest = () => {
        clearTimeouts();
    };

    const addMessage = (message) => {
        const _message = format(new Date(), 'HH:mm:ss') + ' - ' + message;
        setMessageList(oldMessageList => [...oldMessageList, _message]);
    };

    const checkIfItemExist = (id, type, existCb) => {
        const _setTimeout = () => { searchTimer.current = setTimeout(() => { check(); }, TIME); };

        const listExist = () => {
            const items = document.querySelectorAll('[id^="' + id + '"]');
            return items.length > 0 ? items : null;
        };

        const elementExist = () => {
            return document.getElementById(id);
        };

        const check = () => {
            clearTimeout(searchTimer.current);

            if (type === 'LIST') {
                const e = listExist();
                if (e) {
                    existCb(e);
                    clearTimeout(searchTimer.current);
                } else {
                    _setTimeout();
                }
            } else if (type === 'ELEMENT') {
                const e = elementExist();
                if (e) {
                    existCb(e);
                    clearTimeout(searchTimer.current);
                } else {
                    _setTimeout();
                }
            }
        };

        check();
    };

    const doLoginTest = (user, pass) => {
        addMessage('Login with: ' + user + ' - ' + pass);

        // clearTimeouts();
        // genericTimer.current = setTimeout(() => {

        handleLogin(user, pass);

        // }, TIME);
    };

    const doPreExamTest = () => {
        clearTimeouts();
        if (startPageState === 'PRE_AUTH') {
            clearTimeout(genericTimer.current);
            checkIfItemExist('GDPR_CHECKBOX', 'ELEMENT', (e) => {
                genericTimer.current = setTimeout(() => {

                    if (!e.checked) e.click();

                    addMessage('GDPR checkbox checkeded.');

                    doPreExamTest_sendUser();

                }, TIME);
            });
        } else {
            clearTimeout(genericTimer.current);
            checkIfItemExist('POST_AUTH_SEND', 'ELEMENT', (e) => {
                genericTimer.current = setTimeout(() => {

                    e.click();
                    addMessage('Start exam button clicked.');

                }, Math.max(TIME, 2000));
            });
        }
    };

   /* ez elég neccess*/ const doPreExamTest_sendUser = () => {
        clearTimeout(genericTimer.current);
        checkIfItemExist('PRE_AUTH_SEND', 'ELEMENT', (e) => {
            genericTimer.current = setTimeout(() => {

                e.click();
                addMessage('Send button clicked.');

                clearTimeout(genericTimer.current);

                checkIfItemExist('POST_AUTH_SEND', 'ELEMENT', (e) => {
                    genericTimer.current = setTimeout(() => {

                        e.click();
                        addMessage('Start exam button clicked.');

                    }, TIME);
                });

                checkIfItemExist('START_MODAL_ERROR_CLOSE', 'ELEMENT', (e) => {
                    genericTimer.current = setTimeout(() => {

                        e.click();
                        addMessage('Send data error back clicked.');

                        doPreExamTest_sendUser();

                    }, TIME);
                });

            }, TIME);
        });
    };

    const doPreExamTest_startExam = () => {
        // clearTimeout(genericTimer.current);
        checkIfItemExist('POST_AUTH_SEND', 'ELEMENT', (e) => {
            genericTimer.current = setTimeout(() => {

                e.click();
                addMessage('Start exam button clicked.');

            }, TIME);
        });
    };

    const doExamTest = () => {
        clearTimeouts();
        doExamTest_selectAnswer();
    };

    const doExamTest_selectAnswer = () => {
        clearTimeout(genericTimer.current);
        checkIfItemExist('ANSWER_', 'LIST', () => {
            genericTimer.current = setTimeout(() => {

                const possibleAnswerItems = document.querySelectorAll('[id^="ANSWER_"]');
                const randomAnswerItem = possibleAnswerItems[Math.floor(Math.random() * possibleAnswerItems.length)];
                addMessage('Random answer selected: ' + randomAnswerItem.id);

                randomAnswerItem.click();

                doExamTest_paginate('NEXT');

            }, TIME);
        });
    };

    const doExamTest_paginate = () => {
        clearTimeout(genericTimer.current);
        genericTimer.current = setTimeout(() => {

            const nextButton = document.getElementById('EXAM_NEXT_BUTTON');
            const finishButton = document.getElementById('EXAM_FINISH_BUTTON');

            if (nextButton) {
                addMessage('Next button clicked.');
                nextButton.click();
            } else if (finishButton) {
                addMessage('Finish button clicked.');
                finishButton.click();
                doExamTest_finish();
            }

        }, TIME);
    };

    const doExamTest_finish = () => {
        clearTimeout(genericTimer.current);
        checkIfItemExist('EXAM_MODAL_CLOSE', 'ELEMENT', (e) => {
            genericTimer.current = setTimeout(() => {

                e.click();

                clearTimeouts();

                addMessage('Exam finish clicked.');

                doExamTest_finishError();

            }, TIME);
        });
    };

    const doExamTest_finishError = () => {
        clearTimeout(genericTimer.current);
        checkIfItemExist('EXAM_MODAL_ERROR_CLOSE', 'ELEMENT', (e) => {
            genericTimer.current = setTimeout(() => {

                e.click();

                clearTimeouts();

                addMessage('Exam finish error back clicked.');

                doExamTest_paginate();

            }, TIME);
        });
    };

    const value = {
        testEnabled,
        setTestEnabled,
        testInProgress,
        setTestInProgress,
        startPageState,
        setStartPageState,
        messageList,
    };

    return <TestContext.Provider value={value}>{props.children}</TestContext.Provider>;
}