import { useEffect, useRef } from 'react';

import { useTest } from 'test/TestProvider';
import { useAuth } from 'components/AuthProvider';

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};

export default function TestFloater(props) {
    const { handleLogout, accessConfig } = useAuth();
    const { testInProgress, setTestInProgress, messageList } = useTest();

    return (
        <div className='fixed flex flex-col gap-2 z-50 bottom-0 right-0 mr-8 mb-12 bg-white shadow-xl rounded-2xl p-3 w-64 opacity-90 hover:opacity-100'>

            <div className='flex flex-col bg-neutral-100 shadow-inner border rounded-xl px-3 py-1 max-h-64 overflow-y-scroll'>
                {messageList.map((item, index) => (<Message key={item} message={item} />))}
                <AlwaysScrollToBottom />
            </div>

            <button className='w-full btn' onClick={() => setTestInProgress(!testInProgress)}>{testInProgress ? 'Stop test' : 'Start test'}</button>
            {accessConfig && <button className='w-full btn alt' onClick={() => handleLogout()}>Logout</button>}

        </div>
    );
}

function Message(props) {
    return (
        <div className='text-[10px] py-0.5 font-mono border-t first:border-t-0'>
            {props.message}
        </div>
    );
};