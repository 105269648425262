import nBossConnection from 'service/nBossConnection';
import { useEffect } from 'react';
import { useAuth } from 'components/AuthProvider';

function NbossErrorHandler(props) {
	const { handleLogout } = useAuth();

	useEffect(() => {
		nBossConnection.setErrorHandler(error => {
			if (typeof error.response.status !== 'undefined') {
				if (error.response.status === 500) alert('Szerverhiba! Kérem próbálja meg később.');
				else if (error.response.status === 403) handleLogout();
			} else {
				alert(error);
			}
		});
	}, []);

	return <>{props.children}</>;
}

export default NbossErrorHandler;
