import ExamQuestionItemDto from './ExamQuestionItemDto';
import IdentifiableDto from '../IdentifiableDto';

export class ExamQuestionListDto extends IdentifiableDto {
	/**
	 * @module ExamQuestionListDto
	 */
	constructor() {
		super();
		/** @type {ExamQuestionItemDto[]} */
		this.questions = null;
	}

	/**
	 * @typedef {Object} _ExamQuestionListDtoProps
	 * @property {ExamQuestionItemDto[]} questions
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ExamQuestionListDtoProps} ExamQuestionListDtoProps
	 */
	/**
	 *
	 * @param {ExamQuestionListDtoProps} props
	 * @returns {ExamQuestionListDto}
	 */
	setup(props) {
		super.setup(props);
		this.questions = props.questions !== null ? props.questions.map(d => new ExamQuestionItemDto().setup(d)) : null;
		return this;
	}
}

export default ExamQuestionListDto;
