import ExamAnswerSubmitDto from 'dto/exam/ExamAnswerSubmitDto';
import nBossConnection from 'service/nBossConnection';

class VizsgazoValaszService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'vizsgazoValaszControl';
	}

	/**
	 * @param {ExamAnswerSubmitDto} dto
	 * @return {Promise<Object>}
	 */
	storeExamineeAnswer(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/storeExamineeAnswer`, {
					input: dto
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	lockExamineeAnswer() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/lockExamineeAnswer`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	getExamineePoints() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getExamineePoints`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new VizsgazoValaszService();
