import { motion } from 'framer-motion';
import { useAuth } from './AuthProvider';
import { getTranslation } from 'utils/DictionaryUtils';
import { useTest } from 'test/TestProvider';

export default function StatusBar(props) {
    const { accessConfig } = useAuth();

    const { testEnabled, setTestEnabled } = useTest();

    return (
        <motion.div
            className={'bg-white shadow-xl w-full h-6 px-2 flex flex-row items-center justify-center sm:justify-end z-50 ' + (props.className || '')}
            initial={'hidden'}
            animate={accessConfig !== null ? 'visible' : 'hidden'}
            variants={{ visible: { translateY: 0 }, hidden: { translateY: 25 } }}>

            <div className='uppercase font-semibold text-xs tracking-wide text-slate-800'><button className='cursor-default' onDoubleClick={() => setTestEnabled(!testEnabled)}>©</button>{' ' + new Date().getFullYear() + ' - ' + getTranslation('finalexamcenter')}</div>

        </motion.div>
    )
}