import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuth } from 'components/AuthProvider';
import UzenetekService from 'service/message/UzenetekService';
import ModalDialog from 'elements/modal/ModalDialog';
import GlassCard, { GlassCardHeading } from 'elements/GlassCard';
import { getTranslation } from 'utils/DictionaryUtils';

const schema = yup.object().shape({
	levelCim: yup.string().email(getTranslation('email-format')).required(getTranslation('email-needed')),
	tartalom: yup.string().required(getTranslation('complaint-needed'))
});

const defaultValues = {
	levelCim: '',
	tartalom: ''
};

export default function ComplaintMessage(props) {
	const { accessConfig } = useAuth();
	const [showConfirm, setShowConfirm] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const { control, setValue, formState, handleSubmit, reset, trigger, setError, register, getValues } = useForm({
		mode: 'onChange',
		defaultValues: { ...defaultValues, ...{ levelCim: accessConfig.email } },
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	// useEffect(() => {
	// 	setValue('levelCim', '', { shouldDirty: true, shouldValidate: true });
	// 	setValue('tartalom', '', { shouldDirty: true, shouldValidate: true });
	// }, [reset, setValue, trigger]);

	function submit() {
		setSubmitting(true);
		const model = getValues();
		UzenetekService.sendComplain(props.data.vizsgafeladatId, model.levelCim, model.tartalom).then(() => {
			props.onClose();
		});
	}

	return (
		<form className='w-full min-h-full flex items-center justify-center' onSubmit={handleSubmit(() => setShowConfirm(true))}>

			<GlassCard
				className='max-w-2xl'
				topChildren={<GlassCardHeading start={getTranslation('complaint-start')} end={getTranslation('complaint-end')} />}
				mdlChildren={<div className='w-full flex flex-col space-y-2'>

					<div className='flex flex-col -space-y-2'>
						<input className='generic-input z-10' placeholder={getTranslation('email')} {...register('levelCim')} />
						{errors.levelCim && <div className='generic-input-error-message'>{errors.levelCim.message}</div>}
					</div>

					<div className='flex flex-col -space-y-2'>
						<textarea className='generic-input h-44 resize-none z-10' placeholder={getTranslation('complaint-description')} {...register('tartalom')} />
						{errors.tartalom && <div className='generic-input-error-message'>{errors.tartalom.message}</div>}
					</div>

				</div>}
				btmChildren={<div className='flex justify-between flex-col sm:flex-row space-x-6'>

					<button className='flex-1 btn' onClick={() => props.onClose()}>{getTranslation('back')}</button>
					{/* <div className='flex-1'></div> */}
					<button type='submit' className='flex-1 btn' disabled={submitting}>{getTranslation('send')}</button>

				</div>} />

			{showConfirm && <ModalDialog
				title={getTranslation('complaint-start') + ' ' + getTranslation('complaint-end')}
				content={getTranslation('complaint-confirm')}
				positiveButtonLabel={getTranslation('send')}
				onPositiveClick={() => { setShowConfirm(false); submit(); }}
				negativeButtonLabel={getTranslation('cancel')}
				onNegativeClick={() => { setShowConfirm(false); }}
				onClose={() => { setShowConfirm(false); }} />}

		</form>
	);
}
