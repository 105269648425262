import { motion } from 'framer-motion';

export default function Loder(props) {
    return (
        <motion.div
            className='card p-4'
            animate={{ scale: 1, opacity: 1 }}
            initial={{ scale: 0.5, opacity: 0 }}>

            <img src='/assets/images/loader.gif' alt='loader' />

        </motion.div>
    );
}