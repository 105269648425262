import dictionaryConfig from '../config/dictionaryConfig.js'
// import { getWebsite } from '@/utils/WebsiteUtils.js';

const _getTranslation = (lang, key, def) => {

    let translation = def ? def : ('[!' + key + '!]');
    if (dictionaryConfig[key] && dictionaryConfig[key][lang])
        translation = dictionaryConfig[key][lang];


    // console.log(key + '-' + lang + '-' + translation);
    return translation;
};

export const getLanguage = () => {
    return localStorage.getItem('ZVBLanguage') || 'hu-HU';
};

export const setLanguage = (lang) => {
    localStorage.setItem('ZVBLanguage', lang);
    window.location.reload();
};


export const getTranslation = (key) => {
    return _getTranslation(getLanguage(), key, false);
};

export const getTranslationWithDefault = (key, def) => {
    return _getTranslation(getLanguage(), key, def);
};

export const getTranslationForLanguageWithDefault = (lang, key, def) => {
    return _getTranslation(lang, key, def);
};

