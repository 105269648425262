import ExamQuestionListDto from 'dto/exam/ExamQuestionListDto';
import ExamResultDto from 'dto/result/ExamResultDto';
import nBossConnection from 'service/nBossConnection';

class VizsgazoService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'vizsgazoControl';
	}

	/**
	 * @return {Promise<ExamQuestionListDto>}
	 */
	getOnlineExamQuestionListNew() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getOnlineExamQuestionListNew`)
				.then(response => resolve(response.data !== null ? new ExamQuestionListDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<ExamQuestionListDto>}
	 */
	getOnlineExamQuestionListWithResultNew() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getOnlineExamQuestionListWithResultNew`)
				.then(response => resolve(response.data !== null ? new ExamQuestionListDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<ExamResultDto>}
	 */
	getExamResult() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getExamResult`)
				.then(response => resolve(response.data !== null ? new ExamResultDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	// /**
	//  * @param {ExamAnswerSubmitDto} dto
	//  * @return {Promise<Object>}
	//  */
	storeExamineeData(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/storeExamineeData`, {
					input: dto
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new VizsgazoService();
