import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './fonts/fonts.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode><App /></React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();