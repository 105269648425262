import { motion } from 'framer-motion';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useExam } from './exam/ExamProvider';
import { Spin as Hamburger } from 'hamburger-react';

import { getTranslation } from 'utils/DictionaryUtils';

const menu = [{ label: getTranslation('logout'), path: '/login', logout: true }];

export default function Header(props) {
	const { countdown, examInProgress } = useExam();
	const { handleLogout, accessConfig } = useAuth();
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	const countDownString = v => {
		const min = Math.floor(v / 60),
			sec = v - min * 60;
		return String(min) + ':' + (sec < 10 ? '0' : '') + String(sec);
	};

	return (
		<motion.nav
			className="w-full bg-white shadow-xl fixed top-0 h-16 flex flex-row justify-between z-50"
			initial={'hidden'}
			animate={accessConfig !== null ? 'visible' : 'hidden'}
			variants={{ visible: { translateY: 0 }, hidden: { translateY: -75 } }}
		>
			<div className="flex-1 flex flex-row pl-4">
				<div className="flex flex-row items-center space-x-3">
					<img src={'/assets/images/logo-small-blue.png'} alt="" />

					<div className="flex-col items-left tracking-wide hidden sm:flex">
						<div className="font-bold text-slate-800 uppercase leading-tight">{getTranslation('finalexamcenter')}</div>
						<div className="font-light text-xs tracking-wider">{getTranslation('exam')}</div>
					</div>
				</div>
			</div>

			<div className="flex-1 flex justify-center">
				<motion.div initial={countdown ? 'visible' : 'hidden'} animate={countdown ? 'visible' : 'hidden'} transition={{ duration: 0.3, type: 'easeIn' }} variants={{ visible: { translateY: 0 }, hidden: { translateY: -150 } }}>
					<ExamWidget label={getTranslation('exam-time-left')} value={countDownString(countdown)} />
				</motion.div>
			</div>

			<div className="flex-1 flex items-center justify-end pr-4">
				<div className="hidden sm:flex flex-row space-x-4">
					{menu.map((item, index) => (
						<MenuItem key={index} to={item.path} label={item.label} alt={item.logout} visible={!examInProgress} onClick={() => item.logout && handleLogout()} />
					))}
				</div>

				<div className="block sm:hidden -mr-1.5">
					<Hamburger size={28} color="#1e293b" direction="left" toggled={mobileMenuOpen} toggle={setMobileMenuOpen} />
				</div>
			</div>

			<MobileMenu open={mobileMenuOpen} onBackgroundClick={() => setMobileMenuOpen(false)} onItemClick={() => setMobileMenuOpen(false)} />
		</motion.nav>
	);
}

function MenuItem(props) {
	return (
		<NavLink
			className={'btn sm' + (props.alt ? ' alt' : '') + (!props.visible ? ' hidden' : '')}
			to={props.to}
			onClick={() => {
				props.onClick && props.onClick();
			}}
		>
			{props.label}
		</NavLink>
	);
}

function ExamWidget(props) {
	return (
		<div>
			<div className="flex flex-col items-center space-y-1 bg-slate-800 rounded-b-xl shadow-xl p-4 text-white">
				<div className="uppsercase text-sm font-light">{props.label}</div>
				<div className="text-3xl font-bold">{props.value}</div>
			</div>
		</div>
	);
}

function MobileMenu(props) {
	const { examInProgress } = useExam();
	const { handleLogout } = useAuth();

	return (
		<motion.div
			className="fixed w-full h-full flex flex-col items-center justify-center backdrop-blur-sm z-40"
			initial={props.open ? 'visible' : 'hidden'}
			animate={props.open ? 'visible' : 'hidden'}
			variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}
		>
			<motion.div
				className="absolute w-full h-full bg-black bg-opacity-10"
				initial={props.open ? 'visible' : 'hidden'}
				animate={props.open ? 'visible' : 'hidden'}
				variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
				onClick={() => props.onBackgroundClick()}
			/>

			<motion.div
				className="bg-white drop-shadow-xl rounded-xl p-6 flex flex-col items-stretch justify-center space-y-2"
				initial={props.open ? 'visible' : 'hidden'}
				animate={props.open ? 'visible' : 'hidden'}
				variants={{ visible: { scale: 1, opacity: 1 }, hidden: { scale: 1.25, opacity: 0 } }}
			>
				{menu.map((item, index) => (
					<MenuItem
						key={index}
						to={item.path}
						label={item.label}
						alt={item.logout}
						visible={!examInProgress}
						onClick={() => {
							item.logout && handleLogout();
							props.onItemClick();
						}}
					/>
				))}
			</motion.div>
		</motion.div>
	);
}
