import _ from '@lodash';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'components/AuthProvider';
import VizsgazoService from 'service/vizsga/VizsgazoService';
import VizsgazoValaszService from 'service/vizsga/VizsgazoValaszService';
import ExamItem from 'components/exam/ExamItem';
import ExamAnswerSubmitDto from 'dto/exam/ExamAnswerSubmitDto';
import { useExam } from 'components/exam/ExamProvider';
import ModalDialog from 'elements/modal/ModalDialog';
import Loader from 'elements/Loader';
import GlassCard, { GlassCardHeading } from 'elements/GlassCard';
import QuestionListBar from 'components/exam/QuestionListBar';

import { getTranslation } from 'utils/DictionaryUtils';
import { useLocalStore } from 'localstore/LocalStoreProvider';

export default function ExamPage(props) {
	const { accessConfig, handleLogout } = useAuth();
	const { page } = useParams();
	const { queue, sendWithStorage, lockWithStorage } = useLocalStore();
	const { countdown, startCountdown, stopCountdown, questionCount, setQuestionCount, answeredCount, setAnsweredCount, closeExam, setCloseExam, setExamInProgress } = useExam();
	const navigate = useNavigate();

	const [data, setData] = useState(null);
	const [pageNumber, setPageNumber] = useState(page ? Number.parseInt(page, 10) : 0);
	const [answers, setAnswers] = useState([]);
	const [changedAnswer, setChangedAnswer] = useState(false);
	const [showCloseConfirm, setShowCloseConfirm] = useState(false);
	const [examResult, setExamResult] = useState(null);
	const [questionListBarOpen, setQuestionListBarOpen] = useState(false);
	const [closeSendErrorModalVisible, setCloseSendErrorModalVisible] = useState(false);
	const [finishExam, setFinishExam] = useState(null);

	useEffect(() => {
		if (accessConfig.lockedOnlineExam) {
			navigate('/exam-result');
		} else if (!accessConfig.vizsgaKiadhato) {
			navigate('/locked');
		} else {
			setExamInProgress(true);
			VizsgazoService.getOnlineExamQuestionListNew().then(d => {

				startCountdown(accessConfig.vizsgaIdo * 60);
				setQuestionCount(d.questions.length);
				setData(d);
				setAnswers(d.questions.filter(q => !_.isUndefined(q.vizsgazoValasz) && q.vizsgazoValasz !== null).map(aq => { return { vizsgafeladatId: aq.vizsgafeladatId, valaszId: aq.vizsgazoValasz }; }));

				const firstUnansweredQuestion = d.questions.findIndex(q => _.isUndefined(q.vizsgazoValasz) || q.vizsgazoValasz === null);

				if (firstUnansweredQuestion > 0 && pageNumber === 0) navigate(`/exam/${firstUnansweredQuestion}`);

			});
		}

		return () => {
			stopCountdown();
			setQuestionCount(null);
			setAnsweredCount(null);
			setAnswers([]);
		};
	}, []);

	useEffect(() => {
		setChangedAnswer(false);
		setPageNumber(page ? Number.parseInt(page, 10) : 0);
	}, [page]);

	useEffect(() => {
		setAnsweredCount(answers.length);
	}, [answers]);

	useEffect(() => {
		if (countdown === 0) setCloseExam(true);
	}, [countdown]);

	useEffect(() => {
		if (closeExam) {

			setExamInProgress(false);

			if (changedAnswer) {
				console.log('Changed answer.');

				updateAnswerRemote(true,
					() => { lockWithStorage((response) => { _finish(response); }, () => { _resetClose(); }); },
					() => { _resetClose(); }
				);
			} else {
				console.log('No changed answer.');

				if (queue && queue.length > 0) {
					console.log('Queue has data pending, trying send.');

					updateAnswerRemote(true,
						() => { lockWithStorage((response) => { _finish(response); }, () => { _resetClose(); }); },
						() => { _resetClose(); }
					);
				} else {
					console.log('Queue has no data pending.');

					lockWithStorage((response) => { _finish(response); }, () => { _resetClose(); });
				}
			}

		}
	}, [closeExam]);

	const _resetClose = () => {
		setCloseExam(false);
		setExamInProgress(true);
		setCloseSendErrorModalVisible(true);
	};

	const _finish = (result) => {
		setFinishExam(true);
		setExamResult(result.pont);
	};

	const handleAnswerChange = valaszId => {
		const vizsgafeladatId = data.questions[pageNumber].vizsgafeladatId;
		const index = answers.findIndex(item => item.vizsgafeladatId === vizsgafeladatId);
		if (index > -1) {
			if (answers[index].valaszId !== valaszId) {
				setChangedAnswer(true);
				setAnswers(answers.map(item => (item.vizsgafeladatId === vizsgafeladatId ? { vizsgafeladatId, valaszId } : item)));
			}
		} else {
			setChangedAnswer(true);
			setAnswers([
				...answers,
				{
					vizsgafeladatId: data.questions[pageNumber].vizsgafeladatId,
					valaszId
				}
			]);
		}
	};

	const handleNextStep = () => {
		if (changedAnswer) updateAnswerRemote();
	};

	const updateAnswerRemote = (force, cbSuccess, cbFail) => {
		console.log('Update answer remote.');

		const vizsgafeladatId = data.questions[pageNumber].vizsgafeladatId;
		const { valaszId } = answers.find(item => item.vizsgafeladatId === vizsgafeladatId);
		const answerItem = { vizsgafeladatId, valaszId };

		sendWithStorage(answerItem, force, cbSuccess, cbFail);
	};

	return (
		<div className="w-full min-h-full flex items-center justify-center">

			{data === null && <Loader />}

			{(data !== null && finishExam) && <Feedback examResult={examResult} onLogout={() => handleLogout()} />}

			{(data !== null && !finishExam) && (
				<>
					<QuestionListBar currentIndex={pageNumber} questions={data.questions} answers={answers} open={questionListBarOpen} onClose={() => setQuestionListBarOpen(false)} onItemClick={index => handleNextStep(navigate('/exam/' + index))} />

					<ExamItem
						data={data.questions[pageNumber]}
						number={pageNumber + 1}
						answer={answers.find(item => item.vizsgafeladatId === data.questions[pageNumber].vizsgafeladatId)}
						onAnswerChange={valaszId => handleAnswerChange(valaszId)}
						btmChildren={
							<div className="flex justify-between flex-col sm:flex-row space-x-0 sm:space-x-6 space-y-4 sm:space-y-0">
								<Link className="flex-1 btn" to={`/exam/${pageNumber !== 0 ? pageNumber - 1 : 0}`} disabled={pageNumber === 0} onClick={() => handleNextStep()}>
									{getTranslation('prev-question')}
								</Link>

								<Counter pageNumber={pageNumber} questionCount={questionCount} onClick={() => setQuestionListBarOpen(!questionListBarOpen)} />

								{pageNumber === questionCount - 1 ? (
									<button id={'EXAM_FINISH_BUTTON'} className="flex-1 btn" onClick={() => { setShowCloseConfirm(true); }}>{getTranslation('end-exam')}</button>
								) : (
									<Link id={'EXAM_NEXT_BUTTON'} className="flex-1 btn" to={`/exam/${pageNumber !== questionCount - 1 ? pageNumber + 1 : questionCount - 1}`} disabled={pageNumber === questionCount - 1} onClick={() => handleNextStep()}>
										{getTranslation('next-question')}
									</Link>
								)}
							</div>
						}
					/>

				</>
			)}

			{(showCloseConfirm && !closeExam) && (<ModalDialog
				title={getTranslation('end-exam')}
				content={getTranslation('end-exam-confirm').replace('###questionCount', questionCount).replace('###answeredCount', answeredCount)}
				positiveButtonLabel={getTranslation('end-exam')}
				positiveButtonId='EXAM_MODAL_CLOSE'
				onPositiveClick={() => { setShowCloseConfirm(false); setCloseExam(true); }}
				negativeButtonLabel={getTranslation('cancel')}
				negativeButtonId='EXAM_MODAL_BACK'
				onNegativeClick={() => { setShowCloseConfirm(false); }}
				onClose={() => { setShowCloseConfirm(false); }} />)}

			{(closeSendErrorModalVisible && !finishExam) && (<ModalDialog
				title={getTranslation('local-storage-close-modal-title')}
				content={getTranslation('local-storage-close-modal-content')}
				positiveButtonId='EXAM_MODAL_ERROR_CLOSE'
				positiveButtonLabel={getTranslation('back')}
				onPositiveClick={() => { setCloseSendErrorModalVisible(false); }}
				onClose={() => { setCloseSendErrorModalVisible(false); }} />)}

		</div>
	);
}

function Counter(props) {
	return (
		<div className="flex-1 card glass rounded-xl flex items-center justify-center cursor-pointer transition hover:bg-opacity-50" onClick={() => props.onClick()}>
			<div className=" flex items-center justify-center text-white text-sm font-bold py-2">{props.pageNumber + 1 + ' / ' + (props.questionCount ? props.questionCount : '...')}</div>
		</div>
	);
}

function Feedback(props) {
	return (
		<GlassCard
			className="max-w-2xl"
			topChildren={<GlassCardHeading start={getTranslation('exam-feedback-title-start')} end={getTranslation('exam-feedback-title-end')} />}
			mdlChildren={
				<div className="flex flex-col items-center space-y-2">
					<div className="text-slate-800 font-extrabold uppercase">{getTranslation('exam-result')}</div>
					<div className="text-7xl text-sky-700 font-extrabold">{props.examResult ? props.examResult : '-'}</div>
					<div className="text-slate-800 font-extrabold uppercase">{getTranslation('point')}</div>
				</div>
			}
			btmChildren={
				<Link className="btn" to="/login" onClick={() => props.onLogout()}>
					{getTranslation('logout')}
				</Link>
			}
		/>
	);
}
