import { useState, createContext, useContext, useEffect, useRef } from 'react';

export const ExamContext = createContext(null);

export const useExam = () => {
	return useContext(ExamContext);
};

export default function ExamProvider(props) {
	const [countdown, setCountdown] = useState(null);
	const [questionCount, setQuestionCount] = useState(null);
	const [answeredCount, setAnsweredCount] = useState(null);
	const [closeExam, setCloseExam] = useState(false);
	const [examInProgress, setExamInProgress] = useState(false);
	const countdownIntevalRef = useRef(null);

	const startCountdown = v => {
		setCountdown(v);
		countdownIntevalRef.current = setInterval(() => {
			setCountdown(countdown => countdown - 1);
		}, 1000);
	};

	const stopCountdown = () => {
		clearInterval(countdownIntevalRef.current);
		countdownIntevalRef.current = null;
		setCountdown(null);
	};

	useEffect(() => {
		if (countdown === 0) {
			stopCountdown();
		}
	}, [countdown]);

	const value = {
		countdown,
		startCountdown,
		stopCountdown,
		questionCount,
		setQuestionCount,
		answeredCount,
		setAnsweredCount,
		closeExam,
		setCloseExam,
		examInProgress,
		setExamInProgress
	};

	return <ExamContext.Provider value={value}>{props.children}</ExamContext.Provider>;
}
