import IdentifiableDto from '../IdentifiableDto';

export class ExamQuestionItemPossibleAnswerDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {number} */
		this.szam = null;
		/** @type {string} */
		this.szoveg = null;
		/** @type {number} */
		this.vizsgafeladatId = null;
		/** @type {string} */
		this.szovegPlaintext = null;
	}

	/**
	 * @typedef {Object} _ExamQuestionItemPossibleAnswerDtoProps
	 * @property {number} szam
	 * @property {string} szoveg
	 * @property {number} vizsgafeladatId
	 * @property {string} szovegPlaintext
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ExamQuestionItemPossibleAnswerDtoProps} ExamQuestionItemPossibleAnswerDtoProps
	 */
	/**
	 *
	 * @param {ExamQuestionItemPossibleAnswerDtoProps} props
	 * @returns {ExamQuestionItemPossibleAnswerDto}
	 */
	setup(props) {
		super.setup(props);
		this.szam = props.szam;
		this.szoveg = props.szoveg;
		this.vizsgafeladatId = props.vizsgafeladatId;
		this.szovegPlaintext = props.szovegPlaintext;
		return this;
	}
}

export default ExamQuestionItemPossibleAnswerDto;
