import nBossConnection from 'service/nBossConnection';

class UzenetekService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'uzenetekControl';
	}

	/**
	 * @param {number} vizsgafeladatId
	 * @param {string} levelCim
	 * @param {string} tartalom
	 * @return {Promise<Object>}
	 */
	sendComplain(vizsgafeladatId, levelCim, tartalom) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/sendComplain`, {
					vizsgafeladatId,
					levelCim,
					tartalom
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new UzenetekService();
