import dateEnLocale from 'date-fns/locale/en-US';
import dateHuLocale from 'date-fns/locale/hu';
import dateDeLocale from 'date-fns/locale/de';

const dictionaryConfig = {
	lipsum: {
		'hu-HU': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus molestie odio quis finibus. Cras condimentum auctor metus, et hendrerit elit volutpat ac. Praesent massa odio, ullamcorper et ante in, sagittis convallis arcu. Etiam a mollis dui, eu consectetur enim.',
		'en-GB': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus molestie odio quis finibus. Cras condimentum auctor metus, et hendrerit elit volutpat ac. Praesent massa odio, ullamcorper et ante in, sagittis convallis arcu. Etiam a mollis dui, eu consectetur enim.',
		'de-DE': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus molestie odio quis finibus. Cras condimentum auctor metus, et hendrerit elit volutpat ac. Praesent massa odio, ullamcorper et ante in, sagittis convallis arcu. Etiam a mollis dui, eu consectetur enim.'
	},
	finalexamcenter: {
		'hu-HU': 'Záróvizsgaközpont',
		'en-GB': 'Finalexamcenter',
		'de-DE': 'Prüfungszentrum'
	},
	exam: {
		'hu-HU': 'Vizsga',
		'en-GB': 'Exam',
		'de-DE': 'Prüfung'
	},
	'on-exam': {
		'hu-HU': 'Vizsgán',
		'en-GB': 'Exam',
		'de-DE': 'Prüfung'
	},
	login: {
		'hu-HU': 'Bejelentkezés',
		'en-GB': 'Login',
		'de-DE': 'Einmeldung'
	},
	'user-name': {
		'hu-HU': 'Felhasználónév',
		'en-GB': 'Username',
		'de-DE': 'Nutzername'
	},
	email: {
		'hu-HU': 'E-mail cím',
		'en-GB': 'E-mail address',
		'de-DE': 'E-Mail-Addresse'
	},
	password: {
		'hu-HU': 'Jelszó',
		'en-GB': 'Password',
		'de-DE': 'Passwort'
	},
	logout: {
		'hu-HU': 'Kijelentkezés',
		'en-GB': 'Logout',
		'de-DE': 'Ausloggen'
	},
	send: {
		'hu-HU': 'Küldés',
		'en-GB': 'Send',
		'de-DE': 'Senden'
	},
	back: {
		'hu-HU': 'Vissza',
		'en-GB': 'Back',
		'de-DE': 'Zurück'
	},
	cancel: {
		'hu-HU': 'Mégsem',
		'en-GB': 'Cancel',
		'de-DE': 'Abbrechen'
	},
	yes: {
		'hu-HU': 'Igen',
		'en-GB': 'Yes',
		'de-DE': 'Jawohl'
	},
	point: {
		'hu-HU': 'pont',
		'en-GB': 'point',
		'de-DE': 'eunkte'
	},
	'main-page': {
		'hu-HU': 'Kezdőlap',
		'en-GB': 'Main page',
		'de-DE': 'Startseite'
	},
	'email-needed': {
		'hu-HU': 'E-mail cím megadása kötelező!',
		'en-GB': 'Email address is required!',
		'de-DE': 'E-Mailadresse wird benötigt!'
	},
	'email-format': {
		'hu-HU': 'E-mail cím formátuma nem megfelelő!',
		'en-GB': 'Email address is not in the correct format!',
		'de-DE': 'Die E-Mail-Adresse hat nicht das richtige Format!'
	},
	'username-needed': {
		'hu-HU': 'Felhasználónév megadása kötelező!',
		'en-GB': 'Username is required!',
		'de-DE': 'Benutzername ist benötigt!'
	},
	'name-needed': {
		'hu-HU': 'Név megadása kötelező!',
		'en-GB': 'Name is required!',
		'de-DE': 'Name ist benötigt!'
	},
	'born-date-needed': {
		'hu-HU': 'Születési dátum megadása kötelező!',
		'en-GB': 'Date of birth is required!',
		'de-DE': 'Das Geburtsdatum ist benötigt!'
	},
	'born-date-valid': {
		'hu-HU': 'Kérjük érvényes dátumot adjon meg!',
		'en-GB': 'Please enter a valid date!',
		'de-DE': 'Bitte geben Sir ein korrektes Datum an!'
	},
	'neptun-needed': {
		'hu-HU': 'NEPTUN kód megadása kötelező!',
		'en-GB': 'NEPTUN code is required!',
		'de-DE': 'NEPTUN Code ist benötigt!'
	},
	'mothers-name-needed': {
		'hu-HU': 'Anyja neve megadása kötelező!',
		'en-GB': "Your mother's name is required!",
		'de-DE': 'Der Name deiner Mutter ist erforderlich!'
	},
	'enter-password': {
		'hu-HU': 'Kérjük, adja meg jelszavát!',
		'en-GB': 'Please enter your password!',
		'de-DE': 'Bitte geben Sie Ihr Passwort ein!'
	},
	'password-requirements': {
		'hu-HU': 'A jelszó túl rövid. Minimum 4 karakter hosszúságú kell legyen.',
		'en-GB': 'Password is too short.Must be at least 4 characters long.',
		'de-DE': 'Das Passwort ist zu kurz. Muss mindestens 4 Zeichen lang sein.'
	},
	'generic-error': {
		'hu-HU': 'Kérjük, ellenőrizze a megadott adatokat!',
		'en-GB': 'Please check the information provided!',
		'de-DE': 'Bitte überprüfen Sie die bereitgestellten Informationen!'
	},
	'login-error': {
		'hu-HU': 'Hibás felhasználónév vagy jelszó!',
		'en-GB': 'Incorrect username or password!',
		'de-DE': 'Falscher Benutzername oder Passwort!'
	},
	'welcome-title': {
		'hu-HU': 'Üdvözöljük a',
		'en-GB': 'Welcome to your',
		'de-DE': 'Wilkommen zu Ihrer'
	},
	'welcome-text': {
		'hu-HU':
			'<strong>Tisztelt Vizsgázó!</strong><br/><br/>A vizsga elkezdéséhez kattintson a "VIZSGA INDÍTÁSA" feliratú gombra, a vizsgára szánt idő ekkor indul.<br/><br/>A helyesnek vélt válasz megjelölése után a "KÖVETKEZŐ KÉRDÉS" gomb megnyomásával léphet a következő kérdésre.<br/><br/>Kérjük, minden kérdésre válaszoljon. A kérdéslapozók közötti számlálóra kattintva megjelenik egy menü, ahol ellenőrizheti, hogy melyik, valamint mennyi kérdésre válaszolt már. Itt tud a kérdések között tetszőlegesen váltani.<br/><br/>A vizsga befejezése előtt ellenőrizze, hogy minden kérdésre válaszolt-e. A vizsga befejeztével az elért pontszáma azonnal látható.<br/><br/><strong>Jó munkát és sikeres vizsgát kívánunk!</strong>',
		'en-GB':
			'<strong>Dear Young Colleague,</strong><br/><br/>To start the exam, click on the "START EXAM" button, the time allotted for the exam starts then.<br/><br/>After marking the answer you think is correct, you can go to the next question by pressing the "NEXT QUESTION" button.<br/><br/>Please answer all questions. By clicking on the counter between the question pages, a menu will appear where you can check which and how many questions you have already answered. Here you can switch between the questions as you like.<br/><br/>Before completing the exam, check if you have answered all the questions. After completing the exam, your score is immediately visible.<br/><br/><strong>We wish you good work and a successful exam!</strong>',
		'de-DE':
			'<strong>Liebe junge KollegIn!</strong><br/><br/>Um die Prüfung zu starten, klicken Sie auf den Button „PRÜFUNG STARTEN“, die Prüfungszeit beginnt dann.<br/><br/>Nachdem Sie die Ihrer Meinung nach richtige Antwort markiert haben, können Sie zur nächsten Frage gehen, wenn Sie auf den Button „NÄCHSTE FRAGE“ klicken.<br/><br/>Bitte beantworten Sie alle Fragen. Durch Klicken auf den Zähler zwischen den Fragenseiten erscheint ein Menü, in dem Sie nachsehen können welche und wie viele Fragen Sie bereits beantwortet haben. Hier können Sie beliebig zwischen den Fragen wechseln.<br/><br/>Überprüfen Sie vor Abschluss der Prüfung, ob Sie alle Fragen beantwortet haben. Nach Abschluss der Prüfung ist Ihre Punktzahl sofort sichtbar.<br/><br/><strong>Wir wünschen Ihnen gute Arbeit und eine erfolgreiche Prüfung!</strong>'
	},
	'start-exam': {
		'hu-HU': 'Vizsga indítása',
		'en-GB': 'Start exam',
		'de-DE': 'Beginn Prüfung'
	},
	'results-grade-label': {
		'hu-HU': 'Érdemjegy',
		'en-GB': 'Grade',
		'de-DE': 'Note'
	},
	'results-achieved-label': {
		'hu-HU': 'Elért pontszám',
		'en-GB': 'Achieved points',
		'de-DE': 'Erreichten Punkte'
	},
	'results-max-label': {
		'hu-HU': 'Maximális pontszám',
		'en-GB': 'Maximum points',
		'de-DE': 'Höchstpunktzahl'
	},
	'results-overview': {
		'hu-HU': 'Eredmény áttekintés',
		'en-GB': 'Result overview',
		'de-DE': 'Ergebnisübersicht'
	},
	complaint: {
		'hu-HU': 'Reklamáció',
		'en-GB': 'Complaints',
		'de-DE': 'Beschwerden'
	},
	'complaint-description': {
		'hu-HU': 'Reklamáció leírása',
		'en-GB': 'Description of complaint',
		'de-DE': 'Beschreibung der Beschwerde'
	},
	'complaint-needed': {
		'hu-HU': 'Kérjük, adja meg reklamációt!',
		'en-GB': 'Please enter a complaint!',
		'de-DE': 'Bitte geben Sie eine Beschwerde ein!'
	},
	'complaint-confirm': {
		'hu-HU': 'Biztosan beküldi a reklamációt?',
		'en-GB': 'Are you sure you want to submit your complaint?',
		'de-DE': 'Sind Sie sicher, dass Sie Ihre Beschwerde einreichen möchten?'
	},
	'complaint-start': {
		'hu-HU': 'Reklamáció',
		'en-GB': 'Sending a',
		'de-DE': 'Reklamation'
	},
	'complaint-end': {
		'hu-HU': 'küldése',
		'en-GB': 'complaint',
		'de-DE': 'senden'
	},
	'possible-answers': {
		'hu-HU': 'Lehetséges válaszok',
		'en-GB': 'Possible answers',
		'de-DE': 'Mögliche Antworten'
	},
	'correct-solution': {
		'hu-HU': 'Helyes megoldás',
		'en-GB': 'The correct solution',
		'de-DE': 'Die richtige Lösung'
	},
	'correct-solution-explanation': {
		'hu-HU': 'Magyarázat',
		'en-GB': 'Explanation',
		'de-DE': 'Erläuterung'
	},
	'next-question': {
		'hu-HU': 'Következő kérdés',
		'en-GB': 'Next question',
		'de-DE': 'Nächste Frage'
	},
	'prev-question': {
		'hu-HU': 'Előző kérdés',
		'en-GB': 'Previous question',
		'de-DE': 'Vorherige Frage'
	},
	'end-exam': {
		'hu-HU': 'Vizsga befejezése',
		'en-GB': 'Finish of the exam',
		'de-DE': 'Abschluss der Prüfung'
	},
	'end-exam-confirm': {
		'hu-HU': '<strong>Tisztelt Vizsgázó!</strong><br/><br/>Ön ###questionCount kérdésből ###answeredCount darab kérdésre válaszolt. Válaszai digitális aláírásra kerülnek és időbélyeggel látjuk.',
		'en-GB': '<strong>Dear Young Colleague,</strong><br/><br/>You answered ###answeredCount questions out of ###questionCount. Your answers will be digitally signed and submitted.',
		'de-DE': '<strong>Liebe/r junge Kollegin/Kollege!</strong><br/><br/>Sie haben ###answeredCount von ###questionCount Fragen beantwortet. Ihre Antworten werden digital signiert und übermittelt.'
	},
	'exam-feedback-title-start': {
		'hu-HU': 'Köszönjük a',
		'en-GB': 'Thank you for your',
		'de-DE': 'Danke für Ihre'
	},
	'exam-feedback-title-end': {
		'hu-HU': 'részvételt',
		'en-GB': 'participation',
		'de-DE': 'Teilnahme'
	},
	'exam-result': {
		'hu-HU': 'A vizsga eredménye',
		'en-GB': 'The result of the exam',
		'de-DE': 'Das Ergebnis der Prüfung'
	},
	'exam-time-left': {
		'hu-HU': 'Hátralévő idő',
		'en-GB': 'Remaining time',
		'de-DE': 'Verbleibende Zeit'
	},
	'exam-finished-count': {
		'hu-HU': 'Kész',
		'en-GB': 'Finished',
		'de-DE': 'Bereit'
	},
	'exam-summary-all': {
		'hu-HU': 'Összes',
		'en-GB': 'All',
		'de-DE': 'Alle'
	},
	'exam-summary-remaining': {
		'hu-HU': 'Hátralévő',
		'en-GB': 'Remaining',
		'de-DE': 'Verbleibend'
	},
	question: {
		'hu-HU': 'Kérdés',
		'en-GB': 'Question',
		'de-DE': 'Fragen'
	},
	'exam-locked': {
		'hu-HU': 'A vizsga még',
		'en-GB': 'The exam is',
		'de-DE': 'Die Prüfung ist'
	},
	'exam-locked-end': {
		'hu-HU': 'nem elérhető',
		'en-GB': 'not yet available',
		'de-DE': 'noch nicht verfügbar'
	},
	'exam-locked-desc': {
		'hu-HU': 'A vizsga kezdésének ideje',
		'en-GB': 'Exam start time',
		'de-DE': 'Prüfungsbeginn'
	},
	'exam-locked-countdown-desc': {
		'hu-HU': 'A vizsga kezdetéig hátralévő idő',
		'en-GB': 'Time remaining until the start of the exam',
		'de-DE': 'Verbleibende Zeit bis zum Beginn der Prüfung'
	},
	'exam-locked-countdown-desc-sub': {
		'hu-HU': 'A vizsga pontos kezdési időpontjában átirányítjuk a vizsga felületre.',
		'en-GB': 'We will redirect you to the exam interface at the exact start time of the exam.',
		'de-DE': 'Wir leiten Sie zum genauen Startzeitpunkt der Prüfung zur Prüfungsoberfläche weiter.'
	},
	'exam-locked-countdown-redirect': {
		'hu-HU': 'Átiránytjuk a vizsga felületre',
		'en-GB': 'We will redirect you to the exam.',
		'de-DE': 'Wir leiten Sie zur Prüfungsoberfläche weiter.'
	},
	'exam-locked-countdown-redirect-sub': {
		'hu-HU': 'Kérjük, ne zárja be, és ne frissítse az ablakot.',
		'en-GB': 'Please do not close or refresh the window.',
		'de-DE': 'Bitte schließen oder aktualisieren Sie das Fenster nicht.'
	},
	'post-auth-title-start': {
		'en-GB': 'Enter ',
		'hu-HU': 'Szükséges ',
		'de-DE': 'Eingabe '
	},
	'post-auth-title-end': {
		'en-GB': 'necessary data',
		'hu-HU': 'Adatok megadása',
		'de-DE': 'der notwendigen Daten'
	},
	'name': {
		'hu-HU': 'Név',
		'en-GB': 'Name',
		'de-DE': 'Name'
	},
	'birth-date': {
		'hu-HU': 'Születési idő (Év-Hónap-Nap)',
		'en-GB': 'Birth date (YYYY-MM-DD)',
		'de-DE': 'Geburtsdatum (YYYY-MM-DD)',
	},
	'neptun-code': {
		'hu-HU': 'NEPTUN kód',
		'en-GB': 'NEPTUN code',
		'de-DE': 'NEPTUN-Code'
	},
	'mothers-name': {
		'hu-HU': 'Anyja neve',
		'en-GB': "Mother's name",
		'de-DE': 'Name der Mutter'
	},
	'post-auth-description': {
		'hu-HU': 'Kérjük adja meg a személyes adatait, ügyeljen az adatai pontos megadására!',
		'en-GB': 'Please enter your personal data, make sure to enter your data accurately!',
		'de-DE': 'Bitte geben Sie Ihre persönlichen Daten ein, achten Sie auf die korrekte Eingabe Ihrer Daten!'
	},
	'local-storage-modal-title': {
		'hu-HU': 'Figyelem! Nem mentett válaszai vannak!',
		'en-GB': 'Attention! You have unsaved answers!',
		'de-DE': 'Aufmerksamkeit! Sie haben nicht gespeicherte Antworten!'
	},
	'local-storage-modal-content': {
		'hu-HU': 'Hálózati hiba, vagy egyéb probléma végett a küldés sikertelen volt. Kérjük, probálja meg kis idő elteltével újra.',
		'en-GB': 'The submission was unsuccessful due to a network error or other problem. Please try again after a while.',
		'de-DE': 'Die Übermittlung war aufgrund eines Netzwerkfehlers oder eines anderen Problems nicht erfolgreich. Bitte versuchen Sie es nach einer Weile erneut.'
	},
	'generic-close-modal-title': {
		'hu-HU': 'Figyelem!',
		'en-GB': 'Attention!',
		'de-DE': 'Aufmerksamkeit!'
	},
	'local-storage-close-modal-title': {
		'hu-HU': 'Figyelem! Nem mentett válaszai vannak!',
		'en-GB': 'Attention! You have unsaved answers!',
		'de-DE': 'Aufmerksamkeit! Sie haben nicht gespeicherte Antworten!'
	},
	'local-storage-close-modal-content': {
		'hu-HU': 'Hálózati hiba, vagy egyéb probléma végett a beküldés sikertelen volt. Kérjük, probálja meg kis idő elteltével újra.',
		'en-GB': 'The submission was unsuccessful due to a network error or other problem. Please try again after a while.',
		'de-DE': 'Die Übermittlung war aufgrund eines Netzwerkfehlers oder eines anderen Problems nicht erfolgreich. Bitte versuchen Sie es nach einer Weile erneut.'
	},
	'gdpr-label': {
		'hu-HU': 'Az Adatvédelmi Tájékoztató tartalmát megismertem, a benne foglalt feltételeket elfogadom. Az adatvédelmi tájékoztatót <strong><a target="_blank" href="https://online.zarovizsga.hu/static/doc/gdpr-hu.pdf">az alábbi linken</a></strong> olvashatja.',
		'en-GB': 'I have read the content of the Data Protection Notice, I accept the conditions contained therein. You can read the data protection notice at <strong><a target="_blank" href="https://online.zarovizsga.hu/static/doc/gdpr-en.pdf">this link.</a></strong>',
		'de-DE': 'Ich habe den Inhalt der Datenschutzerklärung gelesen und akzeptiere die darin enthaltenen Bedingungen. Die Datenschutzhinweise können Sie unter <strong><atarget="_blank" href="https://online.zarovizsga.hu/static/doc/gdpr-de.pdf">diesem Link</a></strong> lesen.'
	},
	'gdpr-needed': {
		'hu-HU': 'Az Adatvédelmi Tájékoztató feltételeinek elfogadása kötelező!',
		'en-GB': 'Acceptance of the terms of the data protection information is mandatory!',
		'de-DE': 'Die Kenntnisnahme der Datenschutzhinweise ist zwingend erforderlich!'
	},
	'date-time-format': {
		'en-GB': 'MMMM yyyy HH:mm',
		'hu-HU': 'yyyy. MMMM d. HH:mm',
		'de-DE': 'yyyy. MMMM d. HH:mm'
	},
	'date-locale': {
		'en-GB': dateEnLocale,
		'hu-HU': dateHuLocale,
		'de-DE': dateDeLocale
	}
};

export default dictionaryConfig;
