import IdentifiableDto from '../IdentifiableDto';

export class ExamResultItemDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.cSorszam = null;
		/** @type {number} */
		this.fSorszam = null;
		/** @type {boolean} */
		this.helyes = null;
		/** @type {number} */
		this.kerdesId = null;
		/** @type {string} */
		this.sorszam = null;
	}

	/**
	 * @typedef {Object} _ExamResultItemDtoProps
	 * @property {string} cSorszam
	 * @property {number} fSorszam
	 * @property {boolean} helyes
	 * @property {number} kerdesId
	 * @property {string} sorszam
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _ExamResultItemDtoProps} ExamResultItemDtoProps
	 */
	/**
	 *
	 * @param {ExamResultItemDtoProps} props
	 * @returns {ExamResultItemDto}
	 */
	setup(props) {
		super.setup(props);
		this.cSorszam = props.cSorszam;
		this.fSorszam = props.fSorszam;
		this.helyes = props.helyes;
		this.kerdesId = props.kerdesId;
		this.sorszam = props.sorszam;
		return this;
	}
}

export default ExamResultItemDto;
